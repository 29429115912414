import React, { useContext, useEffect, useState, useRef } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { superAdminAddBranch, superAdminProvider } from 'routes/AppUrls';
import ReusableTabs from 'components/common/Tabs';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import SelectInput from 'components/common/Input/SelectInput';
import PhoneNumberInput from 'components/common/Input/PhoneNumberInput';
import ProfileIcon from 'assets/images/navbar/profileIcon.png';
import galleryIcon from 'assets/images/navbar/gallery.png';
import AddBlackColor from 'assets/images/Dashboard/addBlackColor.png';
import image1 from 'assets/images/provider/image1.png';
import crossIcon from 'assets/images/provider/crossIcon.png';
import locationIcon from 'assets/images/provider/location.png';
import ReusableButton from 'components/common/Button';
import trash from 'assets/images/Dashboard/trash.png';
import EditIcon from 'assets/images/Tab/editIcon.png';
import InputAdornment from '@mui/material/InputAdornment';

const AddProvider = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const [tab, setTab] = useState(1);
  const brandImageFileInputRef = useRef();
  const salonBrandImageFileInputRef = useRef();
  const companyBrandImageFileInputRef = useRef();
  const specialistImageRef = useRef();
  const salonImageRef = useRef();
  const companyImageRef = useRef();
  const [brandImageFile, setBrandImageFile] = useState(null);
  const [salonBrandImageFile, setSalonBrandImageFile] = useState(null);
  const [companyBrandImageFile, setCompanyBrandImageFile] = useState(null);
  const [specialistImages, setSpecialistImages] = useState([]);
  const [salonImages, setSalonImages] = useState([]);
  const [companyImages, setCompanyImages] = useState([]);

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isError, setIsError] = useState(null);
  const phoneNumberInputRef = useRef(null);

  const [formValue, setFormValue] = useState({
    country: '',
    countryCode: 'qa',
    countryCodeAlternateNumber: 'qa',
    city: '',
    phoneNumber: '',
    alternatePhoneNumber: '',
    addressArea: '',
    specialistName: '',
    email: '',
    alternateEmail: '',
    about: '',
  });

  const tabData = [
    { label: intl.formatMessage({ id: 'Specialist' }) },
    { label: intl.formatMessage({ id: 'Salon' }) },
    { label: intl.formatMessage({ id: 'Company' }) },
  ];

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Providers" />,
      Link: superAdminProvider(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Add a Provider" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  const handleTabChange = (val) => {
    setTab(val);
  };

  const handleCountryCodeChange = (value, name) => {
    if (name === 'alternatePhoneNumber') {
      setFormValue({ ...formValue, countryCodeAlternateNumber: value });
    } else if (name === 'phoneNumber') {
      setFormValue({ ...formValue, countryCode: value });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setFormValue({ ...formValue, [name]: value });
  };

  const handleBrandImageFileChange = (e) => {
    const file = e.target.files[0];
    setBrandImageFile(URL.createObjectURL(file));
    if (file) {
      let Data = new FormData();
      Data.append('profile_image', file);
    }
  };

  const handleSalonBrandImageFileChange = (e) => {
    const file = e.target.files[0];
    setSalonBrandImageFile(URL.createObjectURL(file));
    if (file) {
      let Data = new FormData();
      Data.append('profile_image', file);
    }
  };

  const handleCompanyBrandImageFileChange = (e) => {
    const file = e.target.files[0];
    setCompanyBrandImageFile(URL.createObjectURL(file));
    if (file) {
      let Data = new FormData();
      Data.append('profile_image', file);
    }
  };

  const handleSpecialistImageChange = (e) => {
    const file = e.target.files[0];
    setSpecialistImages([...specialistImages, URL.createObjectURL(file)]);
    if (file) {
      let Data = new FormData();
      Data.append('profile_image', file);
    }
  };

  const handleSalonImageChange = (e) => {
    const file = e.target.files[0];
    setSalonImages([...salonImages, URL.createObjectURL(file)]);
    if (file) {
      let Data = new FormData();
      Data.append('profile_image', file);
    }
  };

  const handleCompanyImageChange = (e) => {
    const file = e.target.files[0];
    setCompanyImages([...companyImages, URL.createObjectURL(file)]);
    if (file) {
      let Data = new FormData();
      Data.append('profile_image', file);
    }
  };

  const handleBrandImageUpload = () => {
    brandImageFileInputRef.current.click();
  };

  const handleSalonBrandImageUpload = () => {
    salonBrandImageFileInputRef.current.click();
  };

  const handleCompanyBrandImageUpload = () => {
    companyBrandImageFileInputRef.current.click();
  };

  const handleSpecialistImageUpload = () => {
    specialistImageRef.current.click();
  };

  const handleSalonImageUpload = () => {
    salonImageRef.current.click();
  };

  const handleCompanyImageUpload = () => {
    companyImageRef.current.click();
  };

  const handleDeleteSpecialistImage = (data, index) => {
    const filterImages = specialistImages?.filter((value) => value !== data);
    setSpecialistImages(filterImages);
  };

  const handleDeleteSalonImage = (data, index) => {
    const filterImages = salonImages?.filter((value) => value !== data);
    setSalonImages(filterImages);
  };

  const handleDeleteCompanyImage = (data, index) => {
    const filterImages = companyImages?.filter((value) => value !== data);
    setCompanyImages(filterImages);
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
            <FormattedMessage id="Provider Details" />
          </h2>

          <h5 className="fc-292D32 fs-18 fw-400 ff-Akkurat_Bold m-0 mt-4">
            <FormattedMessage id="Business Details" />
          </h5>

          <p className="fc-292D32 fs-14 fw-400 ff-Akkurat m-0 mt-3">
            <FormattedMessage id="Business Type" />
          </p>

          <Col md={12} lg={8} xl={8} xxl={6} className="mt-2">
            <ReusableTabs
              tabs={tabData}
              defaultTab={1}
              currentTab={(val) => handleTabChange(val)}
              hasBorder={'#DDDDDD'}
            />
          </Col>

          {tab === 1 && (
            <>
              <Row className="m-0 p-0">
                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Specialist’s Name" />}
                    placeholder={intl.formatMessage({
                      id: 'Specialist’s Name',
                    })}
                    name="specialistName"
                    type="text"
                    value={formValue?.specialistName}
                    onChange={handleChange}
                    // errorText={
                    //     isError && isError.email.error && isError.email.errorMessage
                    // }
                  />
                </Col>

                <Col xs={12} md={6}></Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={
                      <FormattedMessage id="Address of the serving area" />
                    }
                    placeholder={intl.formatMessage({
                      id: 'Address of the serving area',
                    })}
                    name="addressArea"
                    type="text"
                    value={formValue?.addressArea}
                    onChange={handleChange}
                    // errorText={
                    //     isError && isError.email.error && isError.email.errorMessage
                    // }
                  />
                </Col>

                <Col xs={12} md={3} className="mt-3">
                  <SelectInput
                    label={<FormattedMessage id="Country" />}
                    placeholder={intl.formatMessage({ id: 'Country' })}
                    name={'country'}
                    value={formValue?.country}
                    // data={countryList?.data}
                    onChange={(e) => handleInputChange(e)}
                    // errorText={isError && isError.country.error && isError.country.errorMessage}
                  />
                </Col>

                <Col xs={12} md={3} className="mt-3">
                  <SelectInput
                    label={<FormattedMessage id="City" />}
                    placeholder={intl.formatMessage({ id: 'City' })}
                    name={'city'}
                    value={formValue?.city}
                    // data={countryList?.data}
                    onChange={(e) => handleInputChange(e)}
                    // errorText={isError && isError.country.error && isError.country.errorMessage}
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <PhoneNumberInput
                    label="Contact Number"
                    country={formValue?.countryCode}
                    value={formValue?.countryCode}
                    name={'phoneNumber'}
                    onCountryCodeChange={(e) =>
                      handleCountryCodeChange(e, 'phoneNumber')
                    }
                    onPhoneNumberChange={handleChange}
                    phoneNumber={formValue.phoneNumber}
                    phoneNumberRef={phoneNumberInputRef}
                    isPhoneNumberValid={isPhoneNumberValid}
                    isError={isError}
                    suffixText="verify"
                    errorMessage="Invalid phone number for selected country code"
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <PhoneNumberInput
                    label="Alternate Contact Number"
                    country={formValue?.countryCodeAlternateNumber}
                    value={formValue?.countryCodeAlternateNumber}
                    name={'alternatePhoneNumber'}
                    onCountryCodeChange={(e) =>
                      handleCountryCodeChange(e, 'alternatePhoneNumber')
                    }
                    onPhoneNumberChange={handleChange}
                    phoneNumber={formValue.alternatePhoneNumber}
                    phoneNumberRef={phoneNumberInputRef}
                    isPhoneNumberValid={isPhoneNumberValid}
                    isError={isError}
                    suffixText="verify"
                    errorMessage="Invalid phone number for selected country code"
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Email ID" />}
                    placeholder={intl.formatMessage({ id: 'Email ID' })}
                    name="email"
                    type="text"
                    value={formValue?.email}
                    onChange={handleChange}
                    // errorText={
                    //     isError && isError.email.error && isError.email.errorMessage
                    // }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                            {'verify'}
                          </p>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Alternate Email ID" />}
                    placeholder={intl.formatMessage({
                      id: 'Alternate Email ID',
                    })}
                    name="alternateEmail"
                    type="text"
                    value={formValue?.alternateEmail}
                    onChange={handleChange}
                    // errorText={
                    //     isError && isError.email.error && isError.email.errorMessage
                    // }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                            {'verify'}
                          </p>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>

                <Col xs={12} md={12} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="About/Information" />}
                    placeholder={intl.formatMessage({
                      id: 'About/Information',
                    })}
                    height="100"
                    multiline
                    name="about"
                    value={formValue?.about}
                    onChange={(e) => handleChange(e)}
                    // errorText={isError && isError?.answerEn?.error && isError?.answerEn?.errorMessage}
                  />
                </Col>

                <input
                  ref={brandImageFileInputRef}
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  disabled={false}
                  style={{ display: 'none' }}
                  onChange={handleBrandImageFileChange}
                />

                <div className="mt-4">
                  <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                    Photos
                  </p>
                  <Stack
                    direction="horizontal"
                    gap={4}
                    className="items-center mt-4"
                  >
                    <div className="AvtarImageIcon">
                      {brandImageFile && (
                        <img
                          src={brandImageFile}
                          alt={'icon'}
                          style={{
                            height: '88px',
                            width: '88px',
                            borderRadius: '50%',
                          }}
                        />
                      )}
                      <img
                        src={ProfileIcon}
                        alt="icon"
                        style={{ height: '32px', width: '32px' }}
                        className="profileIcon"
                        onClick={handleBrandImageUpload}
                      />
                    </div>
                    <div>
                      <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                        Upload Brand Image
                      </p>
                      <p className="p-0 m-0 fs-14 fc-9A9A9A fw-400 ff-Akkurat">
                        A picture will help people recognize you
                      </p>
                    </div>
                  </Stack>
                </div>

                <Col xs={12} md={12} className="mt-3">
                  <div className="flex justify-between items-center">
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      <FormattedMessage id="Images" />
                    </p>
                    {specialistImages?.length > 0 && (
                      <ReusableButton
                        fullWidth={false}
                        variant="outlined"
                        color="#292D32"
                        fontSize="16px"
                        fontWeight="400"
                        padding="12px 25px"
                        height="44px"
                        bgColor="transparent"
                        fontFamily="Akkurat"
                        onClick={handleSpecialistImageUpload}
                      >
                        <img
                          src={AddBlackColor}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                          className="me-2"
                        />
                        <FormattedMessage id="Add Images" />
                      </ReusableButton>
                    )}
                  </div>

                  <input
                    ref={specialistImageRef}
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    disabled={false}
                    style={{ display: 'none' }}
                    onChange={handleSpecialistImageChange}
                  />

                  {specialistImages?.length <= 0 ? (
                    <div className="mt-2 bussinessImages flex justify-center align-center">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center cursor-pointer"
                        onClick={handleSpecialistImageUpload}
                      >
                        <img
                          src={AddBlackColor}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                        />
                        <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                          <FormattedMessage id="AddImageEn" />
                        </p>
                      </Stack>
                    </div>
                  ) : (
                    <div className="flex flex-wrap gap-2 mt-3">
                      {specialistImages.map((key, index) => (
                        <div className="relative">
                          <img
                            src={key}
                            alt="image"
                            style={{ height: '122px', width: '246px' }}
                          />
                          <img
                            src={crossIcon}
                            alt="icon"
                            style={{ height: '24px', width: '24px' }}
                            className="absolute top-2 right-2 cursor-pointer"
                            onClick={() =>
                              handleDeleteSpecialistImage(key, index)
                            }
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>

              <Row className="mt-4 items-end m-0 p-0">
                <div className="flex justify-end">
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="items-center"
                  >
                    <ReusableButton
                      fullWidth={false}
                      variant="outlined"
                      color="#292D32"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="transparent"
                      fontFamily="Akkurat"
                      onClick={() => navigate(superAdminProvider(locale))}
                    >
                      <FormattedMessage id="Cancel" />
                    </ReusableButton>

                    <ReusableButton
                      fullWidth={false}
                      variant="contained"
                      color="#F5F5F5"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="#292D32"
                      fontFamily="Akkurat"
                    >
                      <FormattedMessage id="Add a Provider" />
                    </ReusableButton>
                  </Stack>
                </div>
              </Row>
            </>
          )}

          {tab === 2 && (
            <>
              <Row className="m-0 p-0">
                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Owner Name" />}
                    placeholder={intl.formatMessage({ id: 'Owner Name' })}
                    name="ownerName"
                    type="text"
                    value={formValue?.ownerName}
                    onChange={handleChange}
                    // errorText={
                    //     isError && isError.email.error && isError.email.errorMessage
                    // }
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Search business name" />}
                    placeholder={intl.formatMessage({
                      id: 'Search business name',
                    })}
                    name="searchBussinessName"
                    type="text"
                    value={formValue?.searchBussinessName}
                    onChange={handleChange}
                    // errorText={
                    //     isError && isError.email.error && isError.email.errorMessage
                    // }
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={
                      <FormattedMessage id="Address of the serving area" />
                    }
                    placeholder={intl.formatMessage({
                      id: 'Address of the serving area',
                    })}
                    name="addressArea"
                    type="text"
                    value={formValue?.addressArea}
                    onChange={handleChange}
                    // errorText={
                    //     isError && isError.email.error && isError.email.errorMessage
                    // }
                  />
                </Col>

                <Col xs={12} md={3} className="mt-3">
                  <SelectInput
                    label={<FormattedMessage id="Country" />}
                    placeholder={intl.formatMessage({ id: 'Country' })}
                    name={'country'}
                    value={formValue?.country}
                    // data={countryList?.data}
                    onChange={(e) => handleInputChange(e)}
                    // errorText={isError && isError.country.error && isError.country.errorMessage}
                  />
                </Col>

                <Col xs={12} md={3} className="mt-3">
                  <SelectInput
                    label={<FormattedMessage id="City" />}
                    placeholder={intl.formatMessage({ id: 'City' })}
                    name={'city'}
                    value={formValue?.city}
                    // data={countryList?.data}
                    onChange={(e) => handleInputChange(e)}
                    // errorText={isError && isError.country.error && isError.country.errorMessage}
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <PhoneNumberInput
                    label="Contact Number"
                    country={formValue?.countryCode}
                    value={formValue?.countryCode}
                    name={'phoneNumber'}
                    onCountryCodeChange={(e) =>
                      handleCountryCodeChange(e, 'phoneNumber')
                    }
                    onPhoneNumberChange={handleChange}
                    phoneNumber={formValue.phoneNumber}
                    phoneNumberRef={phoneNumberInputRef}
                    isPhoneNumberValid={isPhoneNumberValid}
                    isError={isError}
                    suffixText="verify"
                    errorMessage="Invalid phone number for selected country code"
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <PhoneNumberInput
                    label="Alternate Contact Number"
                    country={formValue?.countryCodeAlternateNumber}
                    value={formValue?.countryCodeAlternateNumber}
                    name={'alternatePhoneNumber'}
                    onCountryCodeChange={(e) =>
                      handleCountryCodeChange(e, 'alternatePhoneNumber')
                    }
                    onPhoneNumberChange={handleChange}
                    phoneNumber={formValue.alternatePhoneNumber}
                    phoneNumberRef={phoneNumberInputRef}
                    isPhoneNumberValid={isPhoneNumberValid}
                    isError={isError}
                    suffixText="verify"
                    errorMessage="Invalid phone number for selected country code"
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Email ID" />}
                    placeholder={intl.formatMessage({ id: 'Email ID' })}
                    name="email"
                    type="text"
                    value={formValue?.email}
                    onChange={handleChange}
                    // errorText={
                    //     isError && isError.email.error && isError.email.errorMessage
                    // }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                            {'verify'}
                          </p>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Alternate Email ID" />}
                    placeholder={intl.formatMessage({
                      id: 'Alternate Email ID',
                    })}
                    name="alternateEmail"
                    type="text"
                    value={formValue?.alternateEmail}
                    onChange={handleChange}
                    // errorText={
                    //     isError && isError.email.error && isError.email.errorMessage
                    // }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                            {'verify'}
                          </p>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>

                <Col xs={12} md={12} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="About/Information" />}
                    placeholder={intl.formatMessage({
                      id: 'About/Information',
                    })}
                    height="100"
                    multiline
                    name="about"
                    value={formValue?.about}
                    onChange={(e) => handleChange(e)}
                    // errorText={isError && isError?.answerEn?.error && isError?.answerEn?.errorMessage}
                  />
                </Col>

                <input
                  ref={salonBrandImageFileInputRef}
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  disabled={false}
                  style={{ display: 'none' }}
                  onChange={handleSalonBrandImageFileChange}
                />

                <div className="mt-4">
                  <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                    Photos
                  </p>
                  <Stack
                    direction="horizontal"
                    gap={4}
                    className="items-center mt-4"
                  >
                    <div className="AvtarImageIcon">
                      {salonBrandImageFile && (
                        <img
                          src={salonBrandImageFile}
                          alt={'icon'}
                          style={{
                            height: '88px',
                            width: '88px',
                            borderRadius: '50%',
                          }}
                        />
                      )}
                      <img
                        src={ProfileIcon}
                        alt="icon"
                        style={{ height: '32px', width: '32px' }}
                        className="profileIcon"
                        onClick={handleSalonBrandImageUpload}
                      />
                    </div>
                    <div>
                      <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                        Upload Brand Image
                      </p>
                      <p className="p-0 m-0 fs-14 fc-9A9A9A fw-400 ff-Akkurat">
                        A picture will help people recognize you
                      </p>
                    </div>
                  </Stack>
                </div>

                <Col xs={12} md={12} className="mt-3">
                  <div className="flex justify-between items-center">
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      <FormattedMessage id="Images" />
                    </p>
                    {salonImages?.length > 0 && (
                      <ReusableButton
                        fullWidth={false}
                        variant="outlined"
                        color="#292D32"
                        fontSize="16px"
                        fontWeight="400"
                        padding="12px 25px"
                        height="44px"
                        bgColor="transparent"
                        fontFamily="Akkurat"
                        onClick={handleSalonImageUpload}
                      >
                        <img
                          src={AddBlackColor}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                          className="me-2"
                        />
                        <FormattedMessage id="Add Images" />
                      </ReusableButton>
                    )}
                  </div>

                  <input
                    ref={salonImageRef}
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    disabled={false}
                    style={{ display: 'none' }}
                    onChange={handleSalonImageChange}
                  />

                  {salonImages?.length <= 0 ? (
                    <div className="mt-2 bussinessImages flex justify-center align-center">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center cursor-pointer"
                        onClick={handleSalonImageUpload}
                      >
                        <img
                          src={AddBlackColor}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                        />
                        <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                          <FormattedMessage id="AddImageEn" />
                        </p>
                      </Stack>
                    </div>
                  ) : (
                    <div className="flex flex-wrap gap-2 mt-3">
                      {salonImages.map((key, index) => (
                        <div className="relative">
                          <img
                            src={key}
                            alt="image"
                            style={{ height: '122px', width: '246px' }}
                          />
                          <img
                            src={crossIcon}
                            alt="icon"
                            style={{ height: '24px', width: '24px' }}
                            className="absolute top-2 right-2 cursor-pointer"
                            onClick={() => handleDeleteSalonImage(key, index)}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </Col>

                <Col xs={12} md={12} className="mt-3">
                  <p className="m-0 p-0 mt-4 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                    {'About Business'}
                  </p>
                </Col>

                <Col xs={12} md={6} className="mt-1">
                  <LabelWithInput
                    label={<FormattedMessage id="Email" />}
                    placeholder={intl.formatMessage({ id: 'Email' })}
                    name="bussinessEmail"
                    type="text"
                    value={formValue?.bussinessEmail}
                    onChange={handleChange}
                    // errorText={
                    //     isError && isError.email.error && isError.email.errorMessage
                    // }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                            {'verify'}
                          </p>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>

                <Col xs={12} md={6} className="mt-1">
                  <PhoneNumberInput
                    label="Contact Number"
                    country={formValue?.countryCodeAlternateNumber}
                    value={formValue?.countryCodeAlternateNumber}
                    name={'bussinessContactNumber'}
                    onCountryCodeChange={(e) =>
                      handleCountryCodeChange(e, 'bussinessContactNumber')
                    }
                    onPhoneNumberChange={handleChange}
                    phoneNumber={formValue.alternatePhoneNumber}
                    phoneNumberRef={phoneNumberInputRef}
                    isPhoneNumberValid={isPhoneNumberValid}
                    isError={isError}
                    suffixText="verify"
                    errorMessage="Invalid phone number for selected country code"
                  />
                </Col>
              </Row>

              <Row className="mt-4 items-end m-0 p-0">
                <div className="flex justify-end">
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="items-center"
                  >
                    <ReusableButton
                      fullWidth={false}
                      variant="outlined"
                      color="#292D32"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="transparent"
                      fontFamily="Akkurat"
                      onClick={() => navigate(superAdminProvider(locale))}
                    >
                      <FormattedMessage id="Cancel" />
                    </ReusableButton>

                    <ReusableButton
                      fullWidth={false}
                      variant="contained"
                      color="#F5F5F5"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="#292D32"
                      fontFamily="Akkurat"
                    >
                      <FormattedMessage id="Send Invitation" />
                    </ReusableButton>
                  </Stack>
                </div>
              </Row>
            </>
          )}

          {tab === 3 && (
            <>
              <Row className="m-0 p-0">
                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Owner Name" />}
                    placeholder={intl.formatMessage({ id: 'Owner Name' })}
                    name="ownerName"
                    type="text"
                    value={formValue?.ownerName}
                    onChange={handleChange}
                    // errorText={
                    //     isError && isError.email.error && isError.email.errorMessage
                    // }
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Search business name" />}
                    placeholder={intl.formatMessage({
                      id: 'Search business name',
                    })}
                    name="searchBussinessName"
                    type="text"
                    value={formValue?.searchBussinessName}
                    onChange={handleChange}
                    // errorText={
                    //     isError && isError.email.error && isError.email.errorMessage
                    // }
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={
                      <FormattedMessage id="Address of the serving area" />
                    }
                    placeholder={intl.formatMessage({
                      id: 'Address of the serving area',
                    })}
                    name="addressArea"
                    type="text"
                    value={formValue?.addressArea}
                    onChange={handleChange}
                    // errorText={
                    //     isError && isError.email.error && isError.email.errorMessage
                    // }
                  />
                </Col>

                <Col xs={12} md={3} className="mt-3">
                  <SelectInput
                    label={<FormattedMessage id="Country" />}
                    placeholder={intl.formatMessage({ id: 'Country' })}
                    name={'country'}
                    value={formValue?.country}
                    // data={countryList?.data}
                    onChange={(e) => handleInputChange(e)}
                    // errorText={isError && isError.country.error && isError.country.errorMessage}
                  />
                </Col>

                <Col xs={12} md={3} className="mt-3">
                  <SelectInput
                    label={<FormattedMessage id="City" />}
                    placeholder={intl.formatMessage({ id: 'City' })}
                    name={'city'}
                    value={formValue?.city}
                    // data={countryList?.data}
                    onChange={(e) => handleInputChange(e)}
                    // errorText={isError && isError.country.error && isError.country.errorMessage}
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <PhoneNumberInput
                    label="Contact Number"
                    country={formValue?.countryCode}
                    value={formValue?.countryCode}
                    name={'phoneNumber'}
                    onCountryCodeChange={(e) =>
                      handleCountryCodeChange(e, 'phoneNumber')
                    }
                    onPhoneNumberChange={handleChange}
                    phoneNumber={formValue.phoneNumber}
                    phoneNumberRef={phoneNumberInputRef}
                    isPhoneNumberValid={isPhoneNumberValid}
                    isError={isError}
                    suffixText="verify"
                    errorMessage="Invalid phone number for selected country code"
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <PhoneNumberInput
                    label="Alternate Contact Number"
                    country={formValue?.countryCodeAlternateNumber}
                    value={formValue?.countryCodeAlternateNumber}
                    name={'alternatePhoneNumber'}
                    onCountryCodeChange={(e) =>
                      handleCountryCodeChange(e, 'alternatePhoneNumber')
                    }
                    onPhoneNumberChange={handleChange}
                    phoneNumber={formValue.alternatePhoneNumber}
                    phoneNumberRef={phoneNumberInputRef}
                    isPhoneNumberValid={isPhoneNumberValid}
                    isError={isError}
                    suffixText="verify"
                    errorMessage="Invalid phone number for selected country code"
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Email ID" />}
                    placeholder={intl.formatMessage({ id: 'Email ID' })}
                    name="email"
                    type="text"
                    value={formValue?.email}
                    onChange={handleChange}
                    // errorText={
                    //     isError && isError.email.error && isError.email.errorMessage
                    // }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                            {'verify'}
                          </p>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>

                <Col xs={12} md={6} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="Alternate Email ID" />}
                    placeholder={intl.formatMessage({
                      id: 'Alternate Email ID',
                    })}
                    name="alternateEmail"
                    type="text"
                    value={formValue?.alternateEmail}
                    onChange={handleChange}
                    // errorText={
                    //     isError && isError.email.error && isError.email.errorMessage
                    // }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                            {'verify'}
                          </p>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>

                <Col xs={12} md={12} className="mt-3">
                  <LabelWithInput
                    label={<FormattedMessage id="About/Information" />}
                    placeholder={intl.formatMessage({
                      id: 'About/Information',
                    })}
                    height="100"
                    multiline
                    name="about"
                    value={formValue?.about}
                    onChange={(e) => handleChange(e)}
                    // errorText={isError && isError?.answerEn?.error && isError?.answerEn?.errorMessage}
                  />
                </Col>

                <Col xs={12} md={12} className="mt-3">
                  <div className="flex justify-between items-center">
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      <FormattedMessage id="Branches" />
                    </p>
                    <ReusableButton
                      fullWidth={false}
                      variant="outlined"
                      color="#292D32"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="transparent"
                      fontFamily="Akkurat"
                      onClick={() => navigate(superAdminAddBranch(locale))}
                    >
                      <img
                        src={AddBlackColor}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                        className="me-2"
                      />
                      <FormattedMessage id="Add Branch" />
                    </ReusableButton>
                  </div>
                </Col>

                <Col xs={12} md={12} className="mt-3">
                  {[1, 2, 3].map((k, i) => (
                    <div className="flex justify-between listBranch p-2 mt-2">
                      <div>
                        <p className="m-0 p-0 fs-18 fc-292D32 fw-400 ff-Akkurat">
                          {'Branch Name'}
                        </p>
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center"
                        >
                          <img
                            src={locationIcon}
                            alt="icon"
                            style={{ height: '20px', width: '20px' }}
                          />
                          <p className="m-0 p-0 fs-16 fc-A4AAB2 fw-400 ff-Akkurat">
                            {'089 Kutch Green Apt. 448, Cadiz, Spain, 458796'}
                          </p>
                        </Stack>
                      </div>
                      <Stack
                        direction="horizontal"
                        gap={3}
                        className="items-center "
                      >
                        <img
                          src={EditIcon}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                        />
                        <img
                          src={trash}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                        />
                      </Stack>
                    </div>
                  ))}
                </Col>

                <input
                  ref={companyBrandImageFileInputRef}
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  disabled={false}
                  style={{ display: 'none' }}
                  onChange={handleCompanyBrandImageFileChange}
                />

                <div className="mt-4">
                  <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                    Photos
                  </p>
                  <Stack
                    direction="horizontal"
                    gap={4}
                    className="items-center mt-4"
                  >
                    <div className="AvtarImageIcon">
                      {companyBrandImageFile && (
                        <img
                          src={companyBrandImageFile}
                          alt={'icon'}
                          style={{
                            height: '88px',
                            width: '88px',
                            borderRadius: '50%',
                          }}
                        />
                      )}
                      <img
                        src={ProfileIcon}
                        alt="icon"
                        style={{ height: '32px', width: '32px' }}
                        className="profileIcon"
                        onClick={handleCompanyBrandImageUpload}
                      />
                    </div>
                    <div>
                      <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                        Upload Brand Image
                      </p>
                      <p className="p-0 m-0 fs-14 fc-9A9A9A fw-400 ff-Akkurat">
                        A picture will help people recognize you
                      </p>
                    </div>
                  </Stack>
                </div>

                <Col xs={12} md={12} className="mt-3">
                  <div className="flex justify-between items-center">
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      <FormattedMessage id="Images" />
                    </p>
                    {companyImages?.length > 0 && (
                      <ReusableButton
                        fullWidth={false}
                        variant="outlined"
                        color="#292D32"
                        fontSize="16px"
                        fontWeight="400"
                        padding="12px 25px"
                        height="44px"
                        bgColor="transparent"
                        fontFamily="Akkurat"
                        onClick={handleCompanyImageUpload}
                      >
                        <img
                          src={AddBlackColor}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                          className="me-2"
                        />
                        <FormattedMessage id="Add Images" />
                      </ReusableButton>
                    )}
                  </div>

                  <input
                    ref={companyImageRef}
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    disabled={false}
                    style={{ display: 'none' }}
                    onChange={handleCompanyImageChange}
                  />

                  {companyImages?.length <= 0 ? (
                    <div className="mt-2 bussinessImages flex justify-center align-center">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center cursor-pointer"
                        onClick={handleCompanyImageUpload}
                      >
                        <img
                          src={AddBlackColor}
                          alt="icon"
                          style={{ height: '24px', width: '24px' }}
                        />
                        <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                          <FormattedMessage id="AddImageEn" />
                        </p>
                      </Stack>
                    </div>
                  ) : (
                    <div className="flex flex-wrap gap-2 mt-3">
                      {companyImages.map((key, index) => (
                        <div className="relative">
                          <img
                            src={key}
                            alt="image"
                            style={{ height: '122px', width: '246px' }}
                          />
                          <img
                            src={crossIcon}
                            alt="icon"
                            style={{ height: '24px', width: '24px' }}
                            className="absolute top-2 right-2 cursor-pointer"
                            onClick={() => handleDeleteCompanyImage(key, index)}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>

              <Row className="mt-4 items-end m-0 p-0">
                <div className="flex justify-end">
                  <Stack
                    direction="horizontal"
                    gap={3}
                    className="items-center"
                  >
                    <ReusableButton
                      fullWidth={false}
                      variant="outlined"
                      color="#292D32"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="transparent"
                      fontFamily="Akkurat"
                      onClick={() => navigate(superAdminProvider(locale))}
                    >
                      <FormattedMessage id="Cancel" />
                    </ReusableButton>

                    <ReusableButton
                      fullWidth={false}
                      variant="contained"
                      color="#F5F5F5"
                      fontSize="16px"
                      fontWeight="400"
                      padding="12px 25px"
                      height="44px"
                      bgColor="#292D32"
                      fontFamily="Akkurat"
                    >
                      <FormattedMessage id="Send Invitation" />
                    </ReusableButton>
                  </Stack>
                </div>
              </Row>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default AddProvider;
