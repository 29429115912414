import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import ServiceLogo from 'assets/images/Dashboard/Logo.png';
import AddBlackColor from 'assets/images/Dashboard/addBlackColor.png';
import ReusableButton from 'components/common/Button';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { superAdminCategory } from 'routes/AppUrls';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import leftArrow from 'assets/images/Dashboard/arrow-left.png';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SwitchInput from 'components/common/Input/SwitchInput';

const EditService = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const [description, setDescription] = useState(false);
  const [checked, setChecked] = useState(false);

  const [formValue, setFormValue] = useState({
    serviceNameEn: '',
    priceEn: '',
    timeRequiredEn: '',
    benefitsEn: '',
    procedureEn: '',
    precautionsEn: '',
    thingstoknowEn: '',
    aftercaretipsEn: '',
    serviceNameAr: '',
    priceAr: '',
    timeRequiredAr: '',
    benefitsAr: '',
    procedureAr: '',
    precautionsAr: '',
    thingstoknowAr: '',
    aftercaretipsAr: '',
  });

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Service Categories" />,
      Link: superAdminCategory(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Edit Service" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  const onEditorStateChange = (newState, name) => {
    setFormValue((prev) => ({
      ...prev,
      [name]: newState,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSwitchChange = (event) => {
    setChecked(!checked);
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <div className="flex justify-between items-center">
            <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
              <Stack direction="horizontal" gap={2} className="items-center">
                <img
                  src={leftArrow}
                  alt="icon"
                  style={{ height: '32px', width: '32px' }}
                  className="cursor-pointer"
                  onClick={() => navigate(superAdminCategory(locale))}
                />
                <p className="p-0 m-0">
                  <FormattedMessage id="Edit Service" />
                </p>
              </Stack>
            </h2>
            <img
              src={ServiceLogo}
              alt={'logo'}
              style={{ height: '64px', width: '96px' }}
            />
          </div>

          <>
            <Row className="m-0 p-0">
              <div className="grid justify-end text-end">
                <SwitchInput
                  label={'En/Ar'}
                  checked={checked}
                  onChange={(e) => handleSwitchChange(e)}
                  color="secondary"
                />
              </div>
            </Row>

            {!checked ? (
              <>
                <Row className="m-0 p-0">
                  <Col xs={12} md={6} className="p-0">
                    <Row className="m-0 p-0">
                      <Col xs={12} md={12}>
                        <LabelWithInput
                          label={<FormattedMessage id="ServiceNameEn" />}
                          placeholder={intl.formatMessage({
                            id: 'ServiceNameEn',
                          })}
                          name="serviceNameEn"
                          type="text"
                          value={formValue?.serviceNameEn}
                          onChange={handleInputChange}
                          // errorText={
                          //     isError && isError.email.error && isError.email.errorMessage
                          // }
                        />
                      </Col>

                      <Col xs={12} md={12} className="p-0">
                        <Row className="m-0 p-0">
                          <Col xs={12} md={6}>
                            <LabelWithInput
                              label={<FormattedMessage id="PriceEn" />}
                              placeholder={intl.formatMessage({
                                id: 'PriceEn',
                              })}
                              name="priceEn"
                              type="text"
                              value={formValue?.priceEn}
                              onChange={handleInputChange}
                              // errorText={
                              //     isError && isError.email.error && isError.email.errorMessage
                              // }
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <LabelWithInput
                              label={<FormattedMessage id="TimeRequiredEn" />}
                              placeholder={intl.formatMessage({
                                id: 'TimeRequiredEn',
                              })}
                              name="timeRequiredEn"
                              type="text"
                              value={formValue?.timeRequiredEn}
                              onChange={handleInputChange}
                              // errorText={
                              //     isError && isError.email.error && isError.email.errorMessage
                              // }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="m-0 p-0">
                  <Col xs={12} md={3} className="mt-3 pt-15">
                    <p className="p-0 m-0 fs-20 fc-292D32 fw-400 ff-Akkurat_Bold">
                      <FormattedMessage id="DescriptionEn" />
                    </p>
                    {!description && (
                      <div
                        className="mt-2 imageBorderWithoutDashed flex justify-center align-center cursor-pointer"
                        onClick={() => setDescription(!description)}
                      >
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center"
                        >
                          <img
                            src={AddBlackColor}
                            alt="icon"
                            style={{ height: '24px', width: '24px' }}
                          />
                          <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                            <FormattedMessage id="AddDescriptionEn" />
                          </p>
                        </Stack>
                      </div>
                    )}
                  </Col>
                </Row>

                {description && (
                  <>
                    <Col xs={12} md={12} className="mt-2">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="ProductsusedEn" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={formValue?.productsusedEn}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'productsusedEn')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="BenefitsEn" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={formValue?.benefitsEn}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'benefitsEn')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="ProcedureEn" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={formValue?.procedureEn}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'procedureEn')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="PrecautionsEn" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={formValue?.precautionsEn}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'precautionsEn')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="ThingstoknowEn" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={formValue?.thingstoknowEn}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'thingstoknowEn')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="AftercaretipsEn" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={formValue?.aftercaretipsEn}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'aftercaretipsEn')
                          }
                        />
                      </div>
                    </Col>
                  </>
                )}
              </>
            ) : (
              <>
                <Row className="m-0 p-0">
                  <Col xs={12} md={6} className="p-0">
                    <Row className="m-0 p-0">
                      <Col xs={12} md={12}>
                        <LabelWithInput
                          label={<FormattedMessage id="ServiceNameAr" />}
                          placeholder={intl.formatMessage({
                            id: 'ServiceNameAr',
                          })}
                          name="serviceNameAr"
                          type="text"
                          value={formValue?.serviceNameAr}
                          onChange={handleInputChange}
                          // errorText={
                          //     isError && isError.email.error && isError.email.errorMessage
                          // }
                        />
                      </Col>

                      <Col xs={12} md={12} className="p-0">
                        <Row className="m-0 p-0">
                          <Col xs={12} md={6}>
                            <LabelWithInput
                              label={<FormattedMessage id="PriceAr" />}
                              placeholder={intl.formatMessage({
                                id: 'PriceAr',
                              })}
                              name="priceAr"
                              type="text"
                              value={formValue?.priceAr}
                              onChange={handleInputChange}
                              // errorText={
                              //     isError && isError.email.error && isError.email.errorMessage
                              // }
                            />
                          </Col>
                          <Col xs={12} md={6}>
                            <LabelWithInput
                              label={<FormattedMessage id="TimeRequiredAr" />}
                              placeholder={intl.formatMessage({
                                id: 'TimeRequiredAr',
                              })}
                              name="timeRequiredAr"
                              type="text"
                              value={formValue?.timeRequiredAr}
                              onChange={handleInputChange}
                              // errorText={
                              //     isError && isError.email.error && isError.email.errorMessage
                              // }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row className="m-0 p-0">
                  <Col xs={12} md={3} className="mt-3 pt-15">
                    <p className="p-0 m-0 fs-20 fc-292D32 fw-400 ff-Akkurat_Bold">
                      <FormattedMessage id="DescriptionAr" />
                    </p>
                    {!description && (
                      <div
                        className="mt-2 imageBorderWithoutDashed flex justify-center align-center cursor-pointer"
                        onClick={() => setDescription(!description)}
                      >
                        <Stack
                          direction="horizontal"
                          gap={2}
                          className="items-center"
                        >
                          <img
                            src={AddBlackColor}
                            alt="icon"
                            style={{ height: '24px', width: '24px' }}
                          />
                          <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                            <FormattedMessage id="AddDescriptionAr" />
                          </p>
                        </Stack>
                      </div>
                    )}
                  </Col>
                </Row>

                {description && (
                  <>
                    <Col xs={12} md={12} className="mt-2">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="ProductsusedAr" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={formValue?.productsusedAr}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'productsusedAr')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="BenefitsAr" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={formValue?.benefitsAr}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'benefitsAr')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="ProcedureAr" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={formValue?.procedureAr}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'procedureAr')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="PrecautionsAr" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={formValue?.precautionsAr}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'precautionsAr')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="ThingstoknowAr" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={formValue?.thingstoknowAr}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'thingstoknowAr')
                          }
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={12} className="mt-4">
                      <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat">
                        <FormattedMessage id="AftercaretipsAr" />
                      </p>
                      <div className="mt-2">
                        <Editor
                          editorState={formValue?.aftercaretipsAr}
                          toolbar={{
                            options: ['inline', 'list', 'textAlign', 'history'],
                            inline: {
                              options: [
                                'bold',
                                'italic',
                                'underline',
                                'strikethrough',
                              ],
                            },
                          }}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          onEditorStateChange={(e) =>
                            onEditorStateChange(e, 'aftercaretipsAr')
                          }
                        />
                      </div>
                    </Col>
                  </>
                )}
              </>
            )}

            <Row className="mt-4 items-end m-0 p-0">
              <div className="flex justify-end">
                <Stack direction="horizontal" gap={3} className="items-center">
                  <ReusableButton
                    fullWidth={false}
                    variant="outlined"
                    color="#292D32"
                    fontSize="16px"
                    fontWeight="400"
                    padding="12px 25px"
                    height="44px"
                    bgColor="transparent"
                    fontFamily="Akkurat"
                    onClick={() => navigate(superAdminCategory(locale))}
                  >
                    <FormattedMessage id="Cancel" />
                  </ReusableButton>

                  <ReusableButton
                    fullWidth={false}
                    variant="contained"
                    color="#F5F5F5"
                    fontSize="16px"
                    fontWeight="400"
                    padding="12px 25px"
                    height="44px"
                    bgColor="#292D32"
                    fontFamily="Akkurat"
                  >
                    <FormattedMessage id="Save & Add" />
                  </ReusableButton>
                </Stack>
              </div>
            </Row>
          </>
        </Row>
      </Col>
    </Row>
  );
};

export default EditService;
