import React, { useContext, useEffect, useState, useRef } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import SelectInput from 'components/common/Input/SelectInput';
import PhoneNumberInput from 'components/common/Input/PhoneNumberInput';
import AddBlackColor from 'assets/images/Dashboard/addBlackColor.png';
import crossIcon from 'assets/images/provider/crossIcon.png';
import ReusableButton from 'components/common/Button';
import InputAdornment from '@mui/material/InputAdornment';
import ProfileIcon from 'assets/images/navbar/profileIcon.png';

const SpecialistProvider = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const brandImageFileInputRef = useRef();
  const specialistImageRef = useRef();
  const [brandImageFile, setBrandImageFile] = useState(null);
  const [specialistImages, setSpecialistImages] = useState([]);

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isError, setIsError] = useState(null);
  const phoneNumberInputRef = useRef(null);

  const [formValue, setFormValue] = useState({
    country: '',
    countryCode: 'qa',
    countryCodeAlternateNumber: 'qa',
    city: '',
    phoneNumber: '',
    alternatePhoneNumber: '',
    addressArea: '',
    specialistName: '',
    email: '',
    alternateEmail: '',
    about: '',
  });

  const handleCountryCodeChange = (value, name) => {
    if (name === 'alternatePhoneNumber') {
      setFormValue({ ...formValue, countryCodeAlternateNumber: value });
    } else if (name === 'phoneNumber') {
      setFormValue({ ...formValue, countryCode: value });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setFormValue({ ...formValue, [name]: value });
  };

  const handleBrandImageFileChange = (e) => {
    const file = e.target.files[0];
    setBrandImageFile(URL.createObjectURL(file));
    if (file) {
      let Data = new FormData();
      Data.append('profile_image', file);
    }
  };

  const handleSpecialistImageChange = (e) => {
    const file = e.target.files[0];
    setSpecialistImages([...specialistImages, URL.createObjectURL(file)]);
    if (file) {
      let Data = new FormData();
      Data.append('profile_image', file);
    }
  };

  const handleBrandImageUpload = () => {
    brandImageFileInputRef.current.click();
  };

  const handleSpecialistImageUpload = () => {
    specialistImageRef.current.click();
  };

  const handleDeleteSpecialistImage = (data, index) => {
    const filterImages = specialistImages?.filter((value) => value !== data);
    setSpecialistImages(filterImages);
  };

  return (
    <>
      <Row className="m-0 p-0">
        <Col xs={12} md={6}>
          <LabelWithInput
            label={<FormattedMessage id="Specialist’s Name" />}
            placeholder={intl.formatMessage({
              id: 'Specialist’s Name',
            })}
            name="specialistName"
            type="text"
            value={formValue?.specialistName}
            onChange={handleChange}
            // errorText={
            //     isError && isError.email.error && isError.email.errorMessage
            // }
          />
        </Col>

        <Col xs={12} md={6}></Col>

        <Col xs={12} md={6} className="mt-3">
          <LabelWithInput
            label={<FormattedMessage id="Address of the serving area" />}
            placeholder={intl.formatMessage({
              id: 'Address of the serving area',
            })}
            name="addressArea"
            type="text"
            value={formValue?.addressArea}
            onChange={handleChange}
            // errorText={
            //     isError && isError.email.error && isError.email.errorMessage
            // }
          />
        </Col>

        <Col xs={12} md={3} className="mt-3">
          <SelectInput
            label={<FormattedMessage id="Country" />}
            placeholder={intl.formatMessage({ id: 'Country' })}
            name={'country'}
            value={formValue?.country}
            // data={countryList?.data}
            onChange={(e) => handleInputChange(e)}
            // errorText={isError && isError.country.error && isError.country.errorMessage}
          />
        </Col>

        <Col xs={12} md={3} className="mt-3">
          <SelectInput
            label={<FormattedMessage id="City" />}
            placeholder={intl.formatMessage({ id: 'City' })}
            name={'city'}
            value={formValue?.city}
            // data={countryList?.data}
            onChange={(e) => handleInputChange(e)}
            // errorText={isError && isError.country.error && isError.country.errorMessage}
          />
        </Col>

        <Col xs={12} md={6} className="mt-3">
          <PhoneNumberInput
            label="Contact Number"
            country={formValue?.countryCode}
            value={formValue?.countryCode}
            name={'phoneNumber'}
            onCountryCodeChange={(e) =>
              handleCountryCodeChange(e, 'phoneNumber')
            }
            onPhoneNumberChange={handleChange}
            phoneNumber={formValue.phoneNumber}
            phoneNumberRef={phoneNumberInputRef}
            isPhoneNumberValid={isPhoneNumberValid}
            isError={isError}
            suffixText="verify"
            errorMessage="Invalid phone number for selected country code"
          />
        </Col>

        <Col xs={12} md={6} className="mt-3">
          <PhoneNumberInput
            label="Alternate Contact Number"
            country={formValue?.countryCodeAlternateNumber}
            value={formValue?.countryCodeAlternateNumber}
            name={'alternatePhoneNumber'}
            onCountryCodeChange={(e) =>
              handleCountryCodeChange(e, 'alternatePhoneNumber')
            }
            onPhoneNumberChange={handleChange}
            phoneNumber={formValue.alternatePhoneNumber}
            phoneNumberRef={phoneNumberInputRef}
            isPhoneNumberValid={isPhoneNumberValid}
            isError={isError}
            suffixText="verify"
            errorMessage="Invalid phone number for selected country code"
          />
        </Col>

        <Col xs={12} md={6} className="mt-3">
          <LabelWithInput
            label={<FormattedMessage id="Email ID" />}
            placeholder={intl.formatMessage({ id: 'Email ID' })}
            name="email"
            type="text"
            value={formValue?.email}
            onChange={handleChange}
            // errorText={
            //     isError && isError.email.error && isError.email.errorMessage
            // }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                    {'verify'}
                  </p>
                </InputAdornment>
              ),
            }}
          />
        </Col>

        <Col xs={12} md={6} className="mt-3">
          <LabelWithInput
            label={<FormattedMessage id="Alternate Email ID" />}
            placeholder={intl.formatMessage({
              id: 'Alternate Email ID',
            })}
            name="alternateEmail"
            type="text"
            value={formValue?.alternateEmail}
            onChange={handleChange}
            // errorText={
            //     isError && isError.email.error && isError.email.errorMessage
            // }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                    {'verify'}
                  </p>
                </InputAdornment>
              ),
            }}
          />
        </Col>

        <Col xs={12} md={12} className="mt-3">
          <LabelWithInput
            label={<FormattedMessage id="About/Information" />}
            placeholder={intl.formatMessage({
              id: 'About/Information',
            })}
            height="100"
            multiline
            name="about"
            value={formValue?.about}
            onChange={(e) => handleChange(e)}
            // errorText={isError && isError?.answerEn?.error && isError?.answerEn?.errorMessage}
          />
        </Col>

        <input
          ref={brandImageFileInputRef}
          type="file"
          accept=".png, .jpg, .jpeg"
          disabled={false}
          style={{ display: 'none' }}
          onChange={handleBrandImageFileChange}
        />

        <div className="mt-4">
          <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
            Photos
          </p>
          <Stack direction="horizontal" gap={4} className="items-center mt-4">
            <div className="AvtarImageIcon">
              {brandImageFile && (
                <img
                  src={brandImageFile}
                  alt={'icon'}
                  style={{ height: '88px', width: '88px', borderRadius: '50%' }}
                />
              )}
              <img
                src={ProfileIcon}
                alt="icon"
                style={{ height: '32px', width: '32px' }}
                className="profileIcon"
                onClick={handleBrandImageUpload}
              />
            </div>
            <div>
              <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                Upload Brand Image
              </p>
              <p className="p-0 m-0 fs-14 fc-9A9A9A fw-400 ff-Akkurat">
                A picture will help people recognize you
              </p>
            </div>
          </Stack>
        </div>

        <Col xs={12} md={12} className="mt-3">
          <div className="flex justify-between items-center">
            <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
              <FormattedMessage id="Images" />
            </p>
            {specialistImages?.length > 0 && (
              <ReusableButton
                fullWidth={false}
                variant="outlined"
                color="#292D32"
                fontSize="16px"
                fontWeight="400"
                padding="12px 25px"
                height="44px"
                bgColor="transparent"
                fontFamily="Akkurat"
                onClick={handleSpecialistImageUpload}
              >
                <img
                  src={AddBlackColor}
                  alt="icon"
                  style={{ height: '24px', width: '24px' }}
                  className="me-2"
                />
                <FormattedMessage id="Add Images" />
              </ReusableButton>
            )}
          </div>

          <input
            ref={specialistImageRef}
            type="file"
            accept=".png, .jpg, .jpeg"
            disabled={false}
            style={{ display: 'none' }}
            onChange={handleSpecialistImageChange}
          />

          {specialistImages?.length <= 0 ? (
            <div className="mt-2 bussinessImages flex justify-center align-center">
              <Stack
                direction="horizontal"
                gap={2}
                className="items-center cursor-pointer"
                onClick={handleSpecialistImageUpload}
              >
                <img
                  src={AddBlackColor}
                  alt="icon"
                  style={{ height: '24px', width: '24px' }}
                />
                <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                  <FormattedMessage id="AddImageEn" />
                </p>
              </Stack>
            </div>
          ) : (
            <div className="flex flex-wrap gap-2 mt-3">
              {specialistImages.map((key, index) => (
                <div className="relative">
                  <img
                    src={key}
                    alt="image"
                    style={{ height: '122px', width: '246px' }}
                  />
                  <img
                    src={crossIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                    className="absolute top-2 right-2 cursor-pointer"
                    onClick={() => handleDeleteSpecialistImage(key, index)}
                  />
                </div>
              ))}
            </div>
          )}
        </Col>
      </Row>
    </>
  );
};

export default SpecialistProvider;
