import { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { Col, Image, Row, Stack } from 'react-bootstrap';
import 'components/sidebar/sidebar.scss';
import 'assets/styles/common.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ToastNotification from 'components/common/Toast';
import { LocaleContext } from 'routes/Router';
import {
  getLandingURL,
  login,
  superAdminAppointments,
  superAdminCampaigns,
  superAdminCategory,
  superAdminCustomer,
  superAdminDashboard,
  superAdminProvider,
  superAdminRequest,
} from 'routes/AppUrls';
import { FormattedMessage, useIntl } from 'react-intl';
import Dashboard from 'assets/images/sidebar/dashboard.png';
import DashboardColor from 'assets/images/sidebar/dashboardColor.png';
import Request from 'assets/images/sidebar/Request.png';
import RequestColor from 'assets/images/sidebar/RequestColor.png';
import Provider from 'assets/images/sidebar/Provider.png';
import ProviderColor from 'assets/images/sidebar/ProviderColor.png';
import Customer from 'assets/images/sidebar/Customer.png';
import CustomerColor from 'assets/images/sidebar/CustomerColor.png';
import Service from 'assets/images/sidebar/Service.png';
import ServiceColor from 'assets/images/sidebar/ServiceColor.png';
import Appointment from 'assets/images/sidebar/Appointment.png';
import AppointmentColor from 'assets/images/sidebar/AppointmentColor.png';
import Campaign from 'assets/images/sidebar/Campaign.png';
import CampaignColor from 'assets/images/sidebar/CampaignColor.png';
import Logout from 'assets/images/sidebar/logout.png';
import { getUserRole } from 'utils/general';
import MenuIcon from '@mui/icons-material/Menu';
import sidebarLogo from 'assets/images/sidebar/sidebarLogo.png';

const Sidebar = (props) => {
  const { window } = props;
  const intl = useIntl();
  const navigate = useNavigate();
  const { locale } = useContext(LocaleContext);
  const dispatch = useDispatch();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [conf, setConf] = ToastNotification();
  const userRole = getUserRole(); // Get the user's role

  const aIndex = useSelector(
    (state) => state?.basicDetailsReducer?.activeIndex
  );

  const [activeIndex, setActiveIndex] = useState(
    aIndex?.activeIndex >= 0 ? aIndex?.activeIndex : 0
  );
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState(null);
  const profileData = useSelector(
    (state) => state?.getProfileReducer?.payload?.data
  );
  const profileAdminData = useSelector(
    (state) => state?.adminProfileReducer?.payload?.data
  );

  const sidebarData = [
    {
      name: intl.formatMessage({ id: 'Dashboard' }),
      icon: Dashboard,
      selectedIcon: DashboardColor,
      roles: ['ProviderAdmin', 'SuperAdmin'],
    },
    // {
    //   name: intl.formatMessage({ id: 'Requests' }),
    //   icon: RequestColor,
    //   selectedIcon: Request,
    //   roles: ['ProviderAdmin', 'SuperAdmin'],
    // },
    {
      name: intl.formatMessage({ id: 'Providers' }),
      icon: ProviderColor,
      selectedIcon: Provider,
      roles: ['ProviderAdmin', 'SuperAdmin'],
    },
    {
      name: intl.formatMessage({ id: 'Customers' }),
      icon: CustomerColor,
      selectedIcon: Customer,
      roles: ['ProviderAdmin', 'SuperAdmin'],
    },
    {
      name: intl.formatMessage({ id: 'Service Categories' }),
      icon: ServiceColor,
      selectedIcon: Service,
      roles: ['ProviderAdmin', 'SuperAdmin'],
    },
    {
      name: intl.formatMessage({ id: 'Appointments' }),
      icon: AppointmentColor,
      selectedIcon: Appointment,
      roles: ['ProviderAdmin', 'SuperAdmin'],
    },
    {
      name: intl.formatMessage({ id: 'Campaigns' }),
      icon: CampaignColor,
      selectedIcon: Campaign,
      roles: ['ProviderAdmin', 'SuperAdmin'],
    },
    // {
    //     name: intl.formatMessage({ id: 'UserManagement' }),
    //     icon: userManagementIcon,
    //     selectedIcon: userManagementIconColor,
    //     roles: ['Administrator'],
    //     children: [
    //         { name: intl.formatMessage({ id: 'Parents/Students' }) },
    //         { name: intl.formatMessage({ id: 'Teachers' }) },
    //         { name: intl.formatMessage({ id: 'InternalTeachers' }) },
    //     ],
    // },
    {
      name: intl.formatMessage({ id: 'Logout' }),
      icon: Logout,
      selectedIcon: Logout,
      roles: ['ProviderAdmin', 'SuperAdmin'],
    },
  ];

  const handleSubmenuToggle = (text, index) => {
    setOpenSubmenuIndex(openSubmenuIndex === index ? null : index);
    setActiveIndex(null);
    // dispatch(
    //   basicDetailsCallApi(
    //     ACTIVE_INDEX,
    //     { activeIndex: index },
    //     { navigate: navigate }
    //   )
    // );
  };

  const handleSubmenuRedirect = (text, index) => {
    setActiveIndex(index);
    switch (text?.name) {
      case intl.formatMessage({ id: '' }):
        // navigate(parentStudentUserManagement(locale));
        break;
      case intl.formatMessage({ id: '' }):
        // navigate(teacherUserManagement(locale));
        break;
      case intl.formatMessage({ id: '' }):
        // navigate(internalTeacherUserManagement(locale));
        break;
    }
  };

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleMenuItemClick = (text, index) => {
    setOpenSubmenuIndex(null);
    switch (text?.name) {
      case intl.formatMessage({ id: 'Dashboard' }):
        navigate(superAdminDashboard(locale));
        break;
      case intl.formatMessage({ id: 'Requests' }):
        navigate(superAdminRequest(locale));
        break;
      case intl.formatMessage({ id: 'Providers' }):
        navigate(superAdminProvider(locale));
        break;
      case intl.formatMessage({ id: 'Customers' }):
        navigate(superAdminCustomer(locale));
        break;
      case intl.formatMessage({ id: 'Service Categories' }):
        navigate(superAdminCategory(locale));
        break;
      case intl.formatMessage({ id: 'Appointments' }):
        navigate(superAdminAppointments(locale));
        break;
      case intl.formatMessage({ id: 'Campaigns' }):
        navigate(superAdminCampaigns(locale));
        break;
      case intl.formatMessage({ id: 'Logout' }):
        navigate(login(locale));
        break;
    }
    setActiveIndex(index);

    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
    // dispatch(
    //   basicDetailsCallApi(
    //     ACTIVE_INDEX,
    //     { activeIndex: index },
    //     { navigate: navigate }
    //   )
    // );
  };

  const handleRedirect = (res) => {
    if (res?.data?.status_code === 200) {
      setConf({
        msg: intl.formatMessage({ id: 'logoutsuccessfully!' }),
        variant: 'success',
      });
      navigate(getLandingURL(locale));
    } else {
      setConf({
        msg: intl.formatMessage({ id: 'Pleasetryagain.' }),
        variant: 'error',
      });
    }
  };

  const ToastErrorFunction = (res) => {
    // setConf({ msg: "Invalid Credential !", variant: 'error' });
  };

  const filteredSidebarData = sidebarData.filter((item) =>
    item.roles.includes('SuperAdmin')
  ); // Filter menu items by userRole

  const drawer = (
    <div>
      <Toolbar className={`flex-col justify-center pl-5 pt-5 pr-5 mt-1`}>
        <img src={sidebarLogo} alt={'logo'} />
      </Toolbar>

      <List className="sidebarList ">
        {filteredSidebarData.map((text, index) => (
          <div key={index}>
            <ListItem
              disablePadding
              className={
                text?.name === intl.formatMessage({ id: 'Logout' })
                  ? 'sidebarBottom'
                  : index + 1 === filteredSidebarData?.length - 1
                    ? `mt-3 mb-5 ${activeIndex === index ? 'borderLeftShow' : 'borderLeftWithoutShow'}`
                    : `mt-3 ${activeIndex === index ? 'borderLeftShow' : 'borderLeftWithoutShow'}`
              }
            >
              <ListItemButton
                onClick={() => {
                  if (text.children) {
                    handleSubmenuToggle(text, index); // Toggle submenu
                  } else {
                    handleMenuItemClick(text, index);
                  }
                }}
                className={
                  activeIndex === index ||
                  (text.children && openSubmenuIndex === index)
                    ? 'active-menu-item '
                    : ''
                }
              >
                <ListItemIcon>
                  <Image
                    src={
                      activeIndex === index ||
                      (text.children && openSubmenuIndex === index)
                        ? text?.selectedIcon
                        : text?.icon
                    }
                    alt={'icon'}
                    style={{ height: '24px', width: '24px' }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={text?.name}
                  className={
                    text?.name === intl.formatMessage({ id: 'Logout' })
                      ? 'Item-logout'
                      : 'Item-text'
                  }
                />
              </ListItemButton>
            </ListItem>

            {/* Submenu */}
            {text.children && openSubmenuIndex === index && (
              <List className="submenuList pb-0">
                {text.children.map((subItem, subIndex) => (
                  <ListItem
                    key={`${index}-${subIndex}`}
                    className="ml-0"
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => {
                        handleSubmenuRedirect(subItem, `${index}-${subIndex}`);
                      }}
                      className={
                        activeIndex === `${index}-${subIndex}`
                          ? 'active-submenu-item'
                          : ''
                      }
                    >
                      <ListItemText
                        className="Item-text"
                        primary={subItem.name}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </div>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Row>
      <Col md={0} lg={2} className="sidebar">
        <Box sx={{ display: 'flex' }}>
          <AppBar>
            <Toolbar className="d-lg-none menu-container">
              <Stack direction="horizontal" gap={4} className="d-flex">
                <MenuIcon
                  style={{
                    fontSize: '2rem',
                    color: '#292D32',
                    cursor: 'pointer',
                  }}
                  onClick={handleDrawerToggle}
                />
                <p className="mb-0 menu-text">
                  <FormattedMessage id="Allue" />
                </p>
              </Stack>
            </Toolbar>
          </AppBar>

          <Box component="nav" aria-label="mailbox folders">
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              anchor={locale === 'en' ? 'left' : 'right'}
              onTransitionEnd={handleDrawerTransitionEnd}
              onClose={handleDrawerClose}
              ModalProps={{
                keepMounted: true,
              }}
              sx={{
                display: { md: 'block', lg: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: '240px',
                },
              }}
            >
              {drawer}
            </Drawer>

            <Drawer
              variant="permanent"
              anchor={locale === 'en' ? 'left' : 'right'}
              className="drawer-menu d-none d-lg-block"
              sx={{
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  borderRight: 'none',
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
        </Box>
      </Col>
    </Row>
  );
};

Sidebar.propTypes = {
  window: PropTypes.func,
};

export default Sidebar;
