import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { styled } from '@mui/system';
import 'assets/styles/common.scss';

const BTab = styled(Tab)(({ theme }) => ({
  fontFamily: 'Akkurat',
  color: '#a2a2a2',
  cursor: 'pointer',
  fontSize: '14px',
  fontWeight: 400,
  backgroundColor: 'transparent',
  borderBottom: '3px solid #FFFFFF !important',
  width: '132px',
  lineHeight: 1.5,
  padding: '6px',
  margin: '6px',
  border: 'none',
  borderRadius: '0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: '20px',
  padding: '0px',

  '&:hover': {
    color: '#292d32',
    borderBottom: '3px solid #292D32',
  },

  '&.Mui-selected': {
    color: '#292D32',
    borderBottom: '3px solid #292D32',
    marginRight: '20px',
    padding: '0px',
  },
}));

const ScrollableTabs = ({
  tabs,
  onTabChange,
  initialTab = 0,
  maxWidth = { xl: 1260 },
}) => {
  const [value, setValue] = useState(initialTab);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onTabChange) {
      onTabChange(newValue); // Notify parent component of tab change
    }
  };

  return (
    // <Box sx={{ maxWidth, bgcolor: 'background.paper' }}>
    <Box sx={{ maxWidth, borderBottom: 1, borderColor: 'divider' }}>
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable tabs"
        TabIndicatorProps={{
          style: { backgroundColor: '#292D32' }, // Customize indicator
        }}
      >
        {tabs.map((tab, index) => (
          <BTab
            key={index}
            label={tab.label}
            icon={
              tab.icon ? (
                <img
                  className={index === value ? '' : 'changeIconColor'}
                  src={tab.icon}
                  alt="icon"
                  style={{ height: '32px', width: '32px' }}
                />
              ) : null
            }
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default ScrollableTabs;
