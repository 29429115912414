import React, { useContext, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import LinkBaseIcon from 'assets/images/Campaigns/LinkSimple.png';
import AssociationBaseIcon from 'assets/images/Campaigns/ShareFat.png';
import trash from 'assets/images/Dashboard/trash.png';
import ReusableButton from 'components/common/Button';
import EditUnderlineIcon from 'assets/images/Category/editUnderlineIcon.png';
import SwitchInput from 'components/common/Input/SwitchInput';
import Avtar from 'assets/images/Dashboard/avtar.png';
import { superAdminAddCampaign } from 'routes/AppUrls';

const Campaigns = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const [checked, setChecked] = useState(false);

  const handleSwitchChange = (event) => {
    setChecked(!checked);
  };

  const weekdayList = [
    { name: 'Sunday', short: 'Su' },
    { name: 'Monday', short: 'Mo' },
    { name: 'Tuesday', short: 'Tu' },
    { name: 'Wednesday', short: 'We' },
    { name: 'Thrusday', short: 'Th' },
    { name: 'Friday', short: 'Fr' },
    { name: 'Saturday', short: 'Sa' },
  ];

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <div className="flex justify-between items-center">
          <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
            <FormattedMessage id="Campaigns" />
          </h2>
          <ReusableButton
            fullWidth={false}
            variant="contained"
            color="#F5F5F5"
            fontSize="16px"
            fontWeight="400"
            padding="12px 24px"
            height="48px"
            bgColor="#292D32"
            fontFamily="Akkurat"
            onClick={() => navigate(superAdminAddCampaign(locale))}
          >
            <FormattedMessage id="Create Campaign" />
          </ReusableButton>
        </div>

        <Row className="mt-3 listHeader m-0 p-0 flex items-center">
          <Col lg={4} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Campaign Name" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Location" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Campaign Type" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Status" />
            </p>
          </Col>
          <Col lg={2} md={6} sm={12}>
            <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
              <FormattedMessage id="Actions" />
            </p>
          </Col>
        </Row>

        <Row className="bc-FFFFFF m-0 p-0">
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((key, index) => (
            <Row className="m-0 flex items-center listCampaign">
              <Col lg={4} md={6} sm={12}>
                <Stack direction="horizontal" gap={3} className="items-center">
                  <img
                    src={Avtar}
                    alt="icon"
                    style={{ height: '60px', width: '60px' }}
                  />
                  <div>
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-600 ff-Akkurat_Bold">
                      {'New Year Fest 2025'}
                    </p>
                    <div className="flex gap-2 mt-1 mb-1">
                      {weekdayList.map((item, index) => (
                        <div className="weekdays bc-808080">
                          <p className="p-0 m-0 fs-12 fc-FFFFFF fw-500 ff-Akkurat">
                            {item?.short}
                          </p>
                        </div>
                      ))}
                    </div>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'20 Dec 2024 - 31 Dec 2024'}
                    </p>
                  </div>
                </Stack>
              </Col>

              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                  {'Jumeirah, Dubai'}
                </p>
              </Col>

              <Col lg={2} md={6} sm={12}>
                <Stack direction="horizontal" gap={3} className="items-center">
                  <img
                    src={index === 0 ? LinkBaseIcon : AssociationBaseIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                    {index === 0 ? 'Link Based' : 'Association Based'}
                  </p>
                </Stack>
              </Col>

              <Col lg={2} md={6} sm={12}>
                <SwitchInput
                  label={true}
                  checked={checked}
                  onChange={(e) => handleSwitchChange(e)}
                  color="secondary"
                />
              </Col>

              <Col lg={2} md={6} sm={12}>
                <Stack direction="horizontal" gap={3} className="items-center ">
                  <img
                    src={EditUnderlineIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                  <img
                    src={trash}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                  />
                </Stack>
              </Col>
            </Row>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default Campaigns;
