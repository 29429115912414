import globalAr from 'utils/locale/common/ar.json';
import categoryAr from 'utils/locale/common/category/ar.json';
import loginAr from 'utils/locale/common/login/ar.json';
import sidebarAr from 'utils/locale/common/sidebar/ar.json';
import dashboardAr from 'utils/locale/common/dashboard/ar.json';
import providerAr from 'utils/locale/common/provider/ar.json';

export default function TranslationsAr() {
  return {
    ...globalAr,
    ...categoryAr,
    ...loginAr,
    ...sidebarAr,
    ...dashboardAr,
    ...providerAr,
  };
}
