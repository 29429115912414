import React, { useContext } from 'react';
import styled from 'styled-components';
import { LocaleContext } from 'routes/Router';
import langIconEn from 'assets/images/navbar/arabicLangIcon.png';
import langIconAr from 'assets/images/navbar/englishLangIcon.png';

const WrapperDiv = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;
const LangSwitcher = () => {
  const { locale, setLocale } = useContext(LocaleContext);

  const LanguageSwitcher = (value) => {
    setLocale(value);
  };

  return (
    <WrapperDiv>
      {locale === 'en' ? (
        <img
          src={langIconAr}
          alt="icon"
          style={{ cursor: 'pointer' }}
          onClick={() => LanguageSwitcher('ar')}
        />
      ) : (
        <img
          src={langIconEn}
          alt="icon"
          style={{ cursor: 'pointer' }}
          onClick={() => LanguageSwitcher('en')}
        />
      )}
    </WrapperDiv>
  );
};

export default LangSwitcher;
