import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import './miniCalendar.scss';

const localizer = momentLocalizer(moment);

const MiniCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [events, setEvents] = useState([
    {
      start: new Date('2024-12-11T09:00:00'),
      end: new Date('2024-12-11T10:00:00'),
      title: 'Appointment with Clyde Johnson',
    },
    {
      start: new Date('2024-12-11T09:30:00'),
      end: new Date('2024-12-11T10:30:00'),
      title: 'Appointment with Clyde Johnson 1',
    },
    {
      start: new Date('2024-12-11T09:40:00'),
      end: new Date('2024-12-11T10:40:00'),
      title: 'Appointment with Clyde Johnson 2',
    },
    {
      start: new Date('2024-12-11T10:00:00'),
      end: new Date('2024-12-11T11:00:00'),
      title: 'Team Meeting',
    },
    {
      start: new Date('2024-12-11T15:00:00'),
      end: new Date('2024-12-11T16:00:00'),
      title: 'Project Discussion',
    },
    {
      start: new Date('2024-12-13T09:00:00'),
      end: new Date('2024-12-13T10:00:00'),
      title: 'Client Call',
    },
  ]);

  const formatDate = (date) => moment(date).format('YYYY-MM-DD');
  const filteredEvents = events.filter(
    (event) => formatDate(event.start) === formatDate(selectedDate)
  );
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const highlightWeekends = ({ date }) => {
    const today = new Date().toDateString();
    const day = date.getDay();
    if (date.toDateString() === selectedDate?.toDateString()) return 'selected';
    if (date.toDateString() === today) return 'today';
    if (day === 0) return 'sunday';
    if (day === 6) return 'saturday';
    return null;
  };

  return (
    <div className="flex gap-4 p-0">
      <div className="calendar-container">
        <Calendar
          next2Label={null}
          prev2Label={null}
          tileClassName={highlightWeekends}
          formatShortWeekday={(locale, date) => date.toString().charAt(0)}
          onChange={handleDateChange}
          value={selectedDate}
        />
      </div>
      <div className="w-100">
        <h2 className="p-0 m-0 fs-24 fw-400 fc-292D32 ff-Akkurat">
          {moment(selectedDate).format('MMM DD, YYYY')}
        </h2>
        <div className="big-calendar-container mt-3">
          <BigCalendar
            key={selectedDate}
            localizer={localizer}
            events={filteredEvents}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
            views={['day']}
            defaultView="day"
            date={selectedDate}
            toolbar={false}
          />
        </div>
      </div>
    </div>
  );
};

export default MiniCalendar;
