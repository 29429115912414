import Appointments from 'Pages/SuperAdmin/Appointments';
import AppointmentsDetails from 'Pages/SuperAdmin/Appointments/AppointmentsDetails';
import Campaigns from 'Pages/SuperAdmin/Campaigns';
import AddCampaign from 'Pages/SuperAdmin/Campaigns/AddCampaign';
import Categories from 'Pages/SuperAdmin/Categories';
import AddCategory from 'Pages/SuperAdmin/Categories/AddCategory';
import AddService from 'Pages/SuperAdmin/Categories/AddService';
import AddSubCategory from 'Pages/SuperAdmin/Categories/AddSubCategory';
import EditCategory from 'Pages/SuperAdmin/Categories/EditCategory';
import EditService from 'Pages/SuperAdmin/Categories/EditService';
import EditSubCategory from 'Pages/SuperAdmin/Categories/EditSubCategory';
import Customer from 'Pages/SuperAdmin/Cutomers';
import CustomerAppointmentDetails from 'Pages/SuperAdmin/Cutomers/CustomerAppoinmentDetails';
import CustomerDetails from 'Pages/SuperAdmin/Cutomers/customerDetails';
import Dashboard from 'Pages/SuperAdmin/Dashboard';
import Login from 'Pages/SuperAdmin/Login';
import SuperAdminProvider from 'Pages/SuperAdmin/Provider';
import AddBranch from 'Pages/SuperAdmin/Provider/addBranch';
import AddProvider from 'Pages/SuperAdmin/Provider/addProvider';
import CompanyDetails from 'Pages/SuperAdmin/Provider/company/companyDetail';
import NewRequestProvider from 'Pages/SuperAdmin/Provider/newRequestProvider';
import OnHoldProvider from 'Pages/SuperAdmin/Provider/onHoldProvider';
import SalonDetails from 'Pages/SuperAdmin/Provider/salon/salonDetail';
import SpecialistDetails from 'Pages/SuperAdmin/Provider/specialist/specialistDetail';
import Request from 'Pages/SuperAdmin/Request';
import {
  getLandingURL,
  login,
  superAdminAddBranch,
  superAdminAddCampaign,
  superAdminAddCategory,
  superAdminAddProvider,
  superAdminAddService,
  superAdminAddSubCategory,
  superAdminAppointments,
  superAdminAppointmentsDetails,
  superAdminCampaigns,
  superAdminCategory,
  superAdminCompanyProviderDetails,
  superAdminCustomer,
  superAdminCustomerAppointmentDetails,
  superAdminCustomerDetails,
  superAdminDashboard,
  superAdminEditCategory,
  superAdminEditService,
  superAdminEditSubCategory,
  superAdminNewRequestProvider,
  superAdminOnHoldProvider,
  superAdminProvider,
  superAdminRequest,
  superAdminSalonProviderDetails,
  superAdminSpecialistProviderDetails,
} from 'routes/AppUrls';

const publicRoutes = [
  {
    path: (locale) => getLandingURL(locale),
    element: <Login />,
    isNoSidebar: true,
    isNoNavbar: true,
  },
  {
    path: (locale) => login(locale),
    element: <Login />,
    isNoSidebar: true,
    isNoNavbar: true,
  },
];

const privateRoutes = [
  // {
  //   path: (locale) => superAdminDashboard(locale),
  //   element: <Dashboard />,
  //   isNoSidebar: false,
  //   isPrivate: true,
  //   allowedRoles: ['SuperAdmin', 'ProviderAdmin'],
  // },
];

const commonRoutes = [
  // {
  //   path: (locale) => aboutUs(locale),
  //   element: <AboutUs />,
  //   isNoSidebar: true,
  //   isCommon: true,
  //   isPrivate: true,
  // },
  {
    path: (locale) => superAdminDashboard(locale),
    element: <Dashboard />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminRequest(locale),
    element: <Request />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminProvider(locale),
    element: <SuperAdminProvider />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminAddProvider(locale),
    element: <AddProvider />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminAddBranch(locale),
    element: <AddBranch />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminNewRequestProvider(locale),
    element: <NewRequestProvider />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminOnHoldProvider(locale),
    element: <OnHoldProvider />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminSpecialistProviderDetails(locale),
    element: <SpecialistDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminSalonProviderDetails(locale),
    element: <SalonDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminCompanyProviderDetails(locale),
    element: <CompanyDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminCustomer(locale),
    element: <Customer />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminCustomerDetails(locale),
    element: <CustomerDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminAppointments(locale),
    element: <Appointments />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminAppointmentsDetails(locale),
    element: <AppointmentsDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminCustomerAppointmentDetails(locale),
    element: <CustomerAppointmentDetails />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminCategory(locale),
    element: <Categories />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminAddCategory(locale),
    element: <AddCategory />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminAddSubCategory(locale),
    element: <AddSubCategory />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminEditCategory(locale),
    element: <EditCategory />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminEditSubCategory(locale),
    element: <EditSubCategory />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminAddService(locale),
    element: <AddService />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminEditService(locale),
    element: <EditService />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminCampaigns(locale),
    element: <Campaigns />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
  {
    path: (locale) => superAdminAddCampaign(locale),
    element: <AddCampaign />,
    isNoSidebar: false,
    isNoNavbar: false,
    isCommon: true,
    isPrivate: true,
  },
];

export const allRoutes = [...publicRoutes, ...privateRoutes, ...commonRoutes];
