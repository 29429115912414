import React, { useContext } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import Button from '@mui/material/Button';
import 'components/navbar/navbar.scss';
// import logoIcon from 'assets/images/Navbar/logo.svg';
import langIconEn from 'assets/images/navbar/arabicLangIcon.png';
import langIconAr from 'assets/images/navbar/englishLangIcon.png';
import { LocaleContext } from 'routes/Router';
import 'assets/styles/common.scss';
import { getLandingURL } from 'routes/AppUrls';
import { useNavigate } from 'react-router-dom';
import { getUserRole } from 'utils/general';
import { useDispatch, useSelector } from 'react-redux';
import ToastNotification from 'components/common/Toast';
import { Avatar } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';

const CustomNavbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [conf, setConf] = ToastNotification();
  const intl = useIntl();
  const { locale, setLocale } = useContext(LocaleContext);

  const profileData = useSelector(
    (state) => state?.getProfileReducer?.payload?.data
  );

  const handleRedirect = (res) => {
    if (res?.data?.status_code === 200) {
      setConf({
        msg: intl.formatMessage({ id: 'logoutsuccessfully!' }),
        variant: 'success',
      });
      navigate(getLandingURL(locale));
    } else {
      setConf({
        msg: intl.formatMessage({ id: 'Pleasetryagain.' }),
        variant: 'error',
      });
    }
  };

  const LanguageSwitcher = (value) => {
    setLocale(value);
  };

  return (
    <Navbar className={`d-block ${locale === 'en' ? 'navbarEn' : 'navbarAr'}`}>
      <Container fluid>
        <Navbar.Toggle aria-controls="navbar-nav" />

        <Navbar.Collapse id="navbar-nav">
          <div
            className={`d-flex ${locale === 'en' ? 'ml-auto' : 'mr-auto'} items-center`}
          >
            <Nav.Link
              className="d-flex align-items-center"
              onClick={() =>
                locale === 'en'
                  ? LanguageSwitcher('ar')
                  : LanguageSwitcher('en')
              }
            >
              <img
                src={locale === 'en' ? langIconAr : langIconEn}
                alt="Language Icon"
                width="40"
                height="40"
                className={locale === 'en' ? 'mr-5' : 'ml-5'}
              />{' '}
            </Nav.Link>
            <div
              className={`d-flex align-items-center ${locale === 'en' ? 'ms-4' : 'me-4'}`}
            >
              <Avatar
                className={locale === 'en' ? 'me-3' : 'ms-3'}
                //   onClick={() => navigate(studentProfile(locale))}
                style={{
                  cursor: 'pointer',
                  backgroundColor: '#D8E3FF',
                  color: '#4573B9',
                }}
              >
                {profileData?.data?.name?.charAt(0)}
              </Avatar>
              <p
                className={`p-0 m-0 fs-14 fw-400 fc-404040 ff-Akkurat ${locale === 'en' ? 'me-4' : 'ms-4'}`}
              >
                {'Admin Name'}
              </p>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
