import React from 'react';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Box from '@mui/material/Box';

const StyledFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    font-size: 16px;
    font-family: Inter_Regular;
    font-weight: 400;
    color: ${(props) => props.theme.labelColor || '#1E1E1E'};
  }
`;

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 52,
  height: 28,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      '& .MuiSwitch-thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + .MuiSwitch-track': {
        backgroundColor: '#34C759',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },

    '& .MuiSwitch-thumb': {
      color: '#FFFFFF',
    },

    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#FFFFFF',
      border: '6px solid #fff',
    },

    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 24,
    height: 24,
  },
  '& .MuiSwitch-track': {
    borderRadius: 28 / 2,
    backgroundColor: '#78788029',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const SwitchInput = (props) => {
  return (
    <Box
      display="flex"
      justifyContent={props?.label ? 'flex-start' : 'center'}
      alignItems={props?.label ? 'flex-start' : 'center'}
      flexDirection={props?.label ? 'row' : 'column'}
      sx={{ width: '100%', height: '100%' }}
    >
      <StyledFormControlLabel
        control={
          <IOSSwitch
            sx={{ m: 1 }}
            checked={props?.checked}
            onChange={props?.onChange}
            color={props?.color || 'primary'}
            size={props?.size || 'medium'}
            disabled={props?.disabled}
          />
        }
        label={props?.label}
        sx={props?.label ? {} : { margin: 0 }}
      />
    </Box>
  );
};

// Default props for reusability
SwitchInput.defaultProps = {
  label: '',
  checked: false,
  onChange: () => {},
  disabled: false,
};

export default SwitchInput;
