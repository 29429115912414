export const getLandingURL = (locale) => `/${locale}`;

////////////////////////SuperAdmin//////////////////////
export const login = (locale) => `/${locale}/login`;
export const superAdminDashboard = (locale) => `/${locale}/dashboard`;
export const superAdminRequest = (locale) => `/${locale}/request`;
export const superAdminProvider = (locale) => `/${locale}/provider`;
export const superAdminAddProvider = (locale) => `/${locale}/add-provider`;
export const superAdminAddBranch = (locale) => `/${locale}/add-branch`;
export const superAdminNewRequestProvider = (locale) =>
  `/${locale}/new-request-provider`;
export const superAdminOnHoldProvider = (locale) =>
  `/${locale}/on-hold-provider`;
export const superAdminSpecialistProviderDetails = (locale) =>
  `/${locale}/specialist-details`;
export const superAdminSalonProviderDetails = (locale) =>
  `/${locale}/salon-details`;
export const superAdminCompanyProviderDetails = (locale) =>
  `/${locale}/company-details`;
export const superAdminCustomer = (locale) => `/${locale}/cutomer`;
export const superAdminCustomerDetails = (locale) =>
  `/${locale}/cutomer-details`;
export const superAdminCustomerAppointmentDetails = (locale) =>
  `/${locale}/cutomer-appointment-details`;
export const superAdminCategory = (locale) => `/${locale}/category`;
export const superAdminAddCategory = (locale) => `/${locale}/add-category`;
export const superAdminAddSubCategory = (locale) =>
  `/${locale}/add-sub-category`;
export const superAdminAddService = (locale) => `/${locale}/add-service`;
export const superAdminEditService = (locale) => `/${locale}/edit-service`;
export const superAdminEditCategory = (locale) => `/${locale}/edit-category`;
export const superAdminEditSubCategory = (locale) =>
  `/${locale}/edit-sub-category`;
export const superAdminAppointments = (locale) => `/${locale}/appointments`;
export const superAdminAppointmentsDetails = (locale) =>
  `/${locale}/appointments-details`;
export const superAdminCampaigns = (locale) => `/${locale}/campaigns`;
export const superAdminAddCampaign = (locale) => `/${locale}/add-campaign`;
