import React, { useContext } from 'react';
import styled from 'styled-components';
import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import MuiLink from 'components/common/MuiLink';
import { LocaleContext } from 'routes/Router';
import { useNavigate } from 'react-router-dom';
import BreadcrumbIcon from 'assets/images/Dashboard/chevron-right.png';

function MuiBreadcrumbs({ list }) {
  const { locale } = useContext(LocaleContext);
  const navigate = useNavigate();

  const handleClick = (link) => {
    navigate(link);
  };

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<img src={BreadcrumbIcon} alt={'icon'} />}
        aria-label="breadcrumb"
      >
        {list.length > 0 &&
          list.map((value) => (
            <>
              {value.isDisbale ? (
                <MuiLink cursor="default" disabled={value?.isDisbale}>
                  {value.title}
                </MuiLink>
              ) : (
                <MuiLink onClick={() => handleClick(value.Link)}>
                  {value.title}
                </MuiLink>
              )}
            </>
          ))}
      </Breadcrumbs>
    </Stack>
  );
}

export default MuiBreadcrumbs;
