import React, { useContext, useState } from 'react';
import { Row, Col, Stack, Image } from 'react-bootstrap';
import DragIndicatorIcon from 'assets/images/Category/DragIndicatorIcon.png';
import CheckBoxInput from 'components/common/Input/CheckBoxInput';
import CaretUpIcon from 'assets/images/Category/CaretUp.png';
import CaretDownIcon from 'assets/images/Category/CaretDown.png';
import EditUnderlineIcon from 'assets/images/Category/editUnderlineIcon.png';
import AddServiceIcon from 'assets/images/Category/AddServiceIcon.png';
import DescriptionIcon from 'assets/images/Category/DescriptionIcon.png';
import DeleteIcon from 'assets/images/Category/DeleteIcon.png';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FormattedMessage, useIntl } from 'react-intl';
import CustomPopover from 'components/common/CutomPopover';
import SubServiceList from './SubServiceList';
import { useNavigate } from 'react-router-dom';
import {
  superAdminAddService,
  superAdminEditSubCategory,
} from 'routes/AppUrls';
import { LocaleContext } from 'routes/Router';

const ServiceList = ({ services, onEdit, onDelete, onAddSubService }) => {
  console.log(onEdit, '1234');
  const navigate = useNavigate();
  const { locale } = useContext(LocaleContext);
  const [expanded, setExpanded] = useState({});
  const [checkedItems, setCheckedItems] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [viewData, setViewData] = useState('');

  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleCheckboxChange = (e, id) => {
    const { name, checked } = e.target;
    // setCheckedItems({ ...checkedItems, [name]: checked });
    setCheckedItems((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handlePopoverClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setViewData(item);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const PopoverData = [
    {
      label: <FormattedMessage id="Add Service" />,
      icon: <Image src={AddServiceIcon} style={{ marginRight: '5px' }} />,
      onClick: () => navigate(superAdminAddService(locale)),
    },
    {
      label: <FormattedMessage id="Edit Sub-Category" />,
      icon: <Image src={DescriptionIcon} style={{ marginRight: '5px' }} />,
      onClick: () => navigate(superAdminEditSubCategory(locale)),
    },
    {
      label: <FormattedMessage id="Delete" />,
      icon: <Image src={DeleteIcon} style={{ marginRight: '5px' }} />,
      // onClick: () => handleEditModel(),
    },
  ];

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Row className="m-0 p-0 mt-3">
      {services.map(
        (service, index) => (
          console.log(service, index, services, '123'),
          (
            <Row
              key={service.id}
              className={`m-0 p-0  ${services?.length === index + 1 ? 'pt-4' : 'border-b py-4'}`}
            >
              {onEdit ? (
                <Col md={6} className="items-center">
                  <div className="flex items-center gap-2">
                    <img
                      src={DragIndicatorIcon}
                      alt={'icon'}
                      style={{ height: '32px', width: '32px' }}
                    />
                    <CheckBoxInput
                      height={'32px'}
                      onChange={(e) => handleCheckboxChange(e, service.id)}
                      checked={expanded[service.id]}
                    />
                    {service.subServices?.length > 0 && (
                      <button
                        className="expand-btn text-lg"
                        onClick={() => toggleExpand(service.id)}
                        aria-label={`Toggle sub-services for ${service.name}`}
                      >
                        {expanded[service.id] ? (
                          <img
                            src={CaretDownIcon}
                            alt={'icon'}
                            style={{ height: '24px', width: '24px' }}
                          />
                        ) : (
                          <img
                            src={CaretUpIcon}
                            alt={'icon'}
                            style={{ height: '24px', width: '24px' }}
                          />
                        )}
                      </button>
                    )}
                    <span className="service-name font-bold">
                      {service.name}
                    </span>
                  </div>
                </Col>
              ) : (
                <Col md={6} className="items-center">
                  <p className="service-name font-bold">{service.name}</p>
                </Col>
              )}

              <Col md={3}>
                <div className="flex items-center gap-5">
                  <span className="service-price text-gray-600">
                    {service.price} {service.currency}
                  </span>
                  <span className="service-time text-gray-600">
                    {service.time} min
                  </span>
                </div>
              </Col>

              {onEdit ? (
                <Col md={3} className="">
                  <Stack
                    direction="horizontal"
                    gap={4}
                    className="items-center justify-end"
                  >
                    <img
                      src={EditUnderlineIcon}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <div>
                      <MoreVertIcon
                        aria-describedby={id}
                        style={{ fill: '#3C1053', cursor: 'pointer' }}
                        onClick={(e) => handlePopoverClick(e, service)}
                      />
                      <CustomPopover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        handleClose={handlePopoverClose}
                        options={PopoverData}
                      />
                    </div>
                  </Stack>
                </Col>
              ) : (
                <Col md={3} className="text-end">
                  {service.subServices?.length > 0 && (
                    <button
                      className="expand-btn text-lg"
                      onClick={() => toggleExpand(service.id)}
                      aria-label={`Toggle sub-services for ${service.name}`}
                    >
                      {expanded[service.id] ? (
                        <img
                          src={CaretDownIcon}
                          alt={'icon'}
                          style={{ height: '24px', width: '24px' }}
                        />
                      ) : (
                        <img
                          src={CaretUpIcon}
                          alt={'icon'}
                          style={{ height: '24px', width: '24px' }}
                        />
                      )}
                    </button>
                  )}
                </Col>
              )}

              <Row className="m-0 p-0">
                {expanded[service.id] && service.subServices?.length > 0 && (
                  <div className="">
                    <SubServiceList
                      services={service.subServices}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      onAddSubService={onAddSubService}
                    />
                  </div>
                )}
              </Row>
            </Row>
          )
        )
      )}
    </Row>
  );
};

export default ServiceList;

// import React, { useContext, useState } from 'react';
// import { Row, Col, Stack, Image } from 'react-bootstrap';
// import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
// import { arrayMoveImmutable } from 'array-move';
// import DragIndicatorIcon from 'assets/images/Category/DragIndicatorIcon.png';
// import CheckBoxInput from 'components/common/Input/CheckBoxInput';
// import CaretUpIcon from 'assets/images/Category/CaretUp.png';
// import CaretDownIcon from 'assets/images/Category/CaretDown.png';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import CustomPopover from 'components/common/CutomPopover';
// import SubServiceList from './SubServiceList';
// import { LocaleContext } from 'routes/Router';

// const DragHandle = SortableHandle(() => (
//   <img
//     src={DragIndicatorIcon}
//     alt="Drag Handle"
//     style={{ height: '32px', width: '32px', cursor: 'grab' }}
//   />
// ));

// const SortableItem = SortableElement(({ service, expanded, handleCheckboxChange, toggleExpand }) => (
//   <>
//     <Row
//       key={service.id}
//       className={`m-0 p-0 ${service.isLast ? 'pt-4' : 'border-b py-4'}`}
//     >
//       <Col md={6} className="items-center">
//         <div className="flex items-center gap-2">
//           <DragHandle />
//           <CheckBoxInput
//             height={'32px'}
//             onChange={(e) => handleCheckboxChange(e, service.id)}
//             checked={expanded[service.id]}
//           />
//           {service.subServices?.length > 0 && (
//             <button
//               className="expand-btn text-lg"
//               onClick={() => toggleExpand(service.id)}
//               aria-label={`Toggle sub-services for ${service.name}`}
//             >
//               {expanded[service.id] ? (
//                 <img src={CaretDownIcon} alt="Caret Down" style={{ height: '24px', width: '24px' }} />
//               ) : (
//                 <img src={CaretUpIcon} alt="Caret Up" style={{ height: '24px', width: '24px' }} />
//               )}
//             </button>
//           )}
//           <span className="service-name font-bold">{service.name}</span>
//         </div>
//       </Col>
//       <Col md={3}>
//         <div className="flex items-center gap-5">
//           <span className="service-price text-gray-600">{service.price} {service.currency}</span>
//           <span className="service-time text-gray-600">{service.time} min</span>
//         </div>
//       </Col>
//       <Col md={3}>
//         <Stack direction="horizontal" gap={4} className="items-center justify-end">
//           <MoreVertIcon style={{ fill: '#3C1053', cursor: 'pointer' }} />

//         </Stack>
//       </Col>
//     </Row>
//   </>
// ));

// const SortableList = SortableContainer(({ services, expanded, handleCheckboxChange, toggleExpand }) => (
//   <Row className="m-0 p-0 mt-3">
//     {services.map((service, index) => (
//       <SortableItem
//         key={`item-${service.id}`}
//         index={index}
//         service={service}
//         expanded={expanded}
//         handleCheckboxChange={handleCheckboxChange}
//         toggleExpand={toggleExpand}
//       />
//     ))}
//   </Row>
// ));

// const ServiceList = ({ services, onEdit, onDelete, onAddSubService }) => {
//   const [expanded, setExpanded] = useState({});
//   const [sortedServices, setSortedServices] = useState(services);

//   const toggleExpand = (id) => {
//     setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
//   };

//   const handleCheckboxChange = (e, id) => {
//     const { checked } = e.target;
//     setExpanded((prev) => ({ ...prev, [id]: checked }));
//   };

//   const onSortEnd = ({ oldIndex, newIndex }) => {
//     setSortedServices(arrayMoveImmutable(sortedServices, oldIndex, newIndex));
//   };

//   return (
//     <SortableList
//       services={sortedServices}
//       expanded={expanded}
//       handleCheckboxChange={handleCheckboxChange}
//       toggleExpand={toggleExpand}
//       onSortEnd={onSortEnd}
//       useDragHandle
//     />
//   );
// };

// export default ServiceList;
