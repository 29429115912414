import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import ServiceLogo from 'assets/images/Dashboard/Logo.png';
import ReusableButton from 'components/common/Button';
import addIcon from 'assets/images/Dashboard/add.png';
import AddBlackColor from 'assets/images/Dashboard/addBlackColor.png';
import Frame1 from 'assets/images/Tab/Frame1.png';
import Frame2 from 'assets/images/Tab/Frame2.png';
import Frame3 from 'assets/images/Tab/Frame3.png';
import Frame4 from 'assets/images/Tab/Frame4.png';
import Frame5 from 'assets/images/Tab/Frame5.png';
import Frame6 from 'assets/images/Tab/Frame6.png';
import Frame7 from 'assets/images/Tab/Frame7.png';
import EditIcon from 'assets/images/Tab/editIcon.png';
import {
  superAdminAddCategory,
  superAdminAddService,
  superAdminAddSubCategory,
  superAdminEditCategory,
} from 'routes/AppUrls';
import ScrollableTabs from 'components/common/SrollableTabs';
import ServiceList from './seviceList';

const Categories = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = [
    { label: 'Hair', icon: Frame7 },
    { label: 'Massage', icon: Frame5 },
    { label: 'Skin & nails', icon: Frame1 },
    { label: 'Makeup', icon: Frame6 },
    { label: 'Brow & lash', icon: Frame2 },
    { label: 'Bridal', icon: Frame3 },
    { label: 'Cosmetic clinics', icon: Frame4 },
    { label: 'Tab Eight', icon: Frame6 },
  ];

  const handleTabChange = (newValue) => {
    setSelectedTab(newValue);
  };

  const [services, setServices] = React.useState([
    {
      id: 1,
      name: 'Haircut',
      price: 20,
      currency: 'SAR',
      time: 30,
      subServices: [],
    },
    {
      id: 2,
      name: 'Hair Treatment',
      price: 20,
      currency: 'SAR',
      time: 30,
      subServices: [
        { id: 21, name: 'Roots', price: 40, currency: 'SAR', time: 60 },
        { id: 22, name: 'Highlight', price: 40, currency: 'SAR', time: 60 },
        { id: 23, name: 'Highlight5', price: 45, currency: 'SAR', time: 65 },
      ],
    },
    {
      id: 3,
      name: 'Hair Color',
      price: 40,
      currency: 'SAR',
      time: 60,
      subServices: [{ id: 31, name: 'Single Color', price: 40, time: 60 }],
    },
  ]);

  const handleAddSubService = (parentId) => {
    const newSubService = {
      id: Date.now(),
      name: 'New Sub-service',
      price: 0,
      time: 0,
    };
    setServices((prev) =>
      prev.map((service) =>
        service.id === parentId
          ? { ...service, subServices: [...service.subServices, newSubService] }
          : service
      )
    );
  };

  const handleEditService = (id, updatedService) => {
    const updateService = (service) => {
      if (service.id === id) return { ...service, ...updatedService };
      if (service.subServices) {
        return {
          ...service,
          subServices: service.subServices.map(updateService),
        };
      }
      return service;
    };

    setServices((prev) => prev.map(updateService));
  };

  const handleDeleteService = (id) => {
    const removeService = (service) => {
      if (service.subServices) {
        service.subServices = service.subServices.filter(
          (sub) => sub.id !== id
        );
      }
      return service.id !== id;
    };

    setServices((prev) => prev.filter(removeService));
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <div className="flex justify-between items-center">
            <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
              <FormattedMessage id="Services & Categories" />
            </h2>
            <img
              src={ServiceLogo}
              alt={'logo'}
              style={{ height: '64px', width: '96px' }}
            />
          </div>

          {false ? (
            <Row className="mt-5 pt-5 text-center">
              <p className="fc-292D32 fs-16 fw-400 ff-Akkurat m-0">
                <FormattedMessage id="No category added" />
              </p>
              <div className="mt-4 mb-5">
                <ReusableButton
                  fullWidth={false}
                  variant="contained"
                  color="#F5F5F5"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 25px"
                  height="44px"
                  bgColor="#292D32"
                  fontFamily="Akkurat"
                  onClick={() => navigate(superAdminAddCategory(locale))}
                >
                  <img
                    src={addIcon}
                    alt="icon"
                    style={{ height: '24px', width: '24px' }}
                    className="me-2"
                  />
                  <FormattedMessage id="Add Category" />
                </ReusableButton>
              </div>
            </Row>
          ) : (
            <>
              <Row className="mt-4 m-0 p-0">
                <div className="flex justify-between items-center">
                  <h2 className="fc-292D32 fs-24 fw-400 ff-Akkurat_Bold m-0">
                    <FormattedMessage id="Categories" />
                  </h2>
                  <ReusableButton
                    fullWidth={false}
                    variant="outlined"
                    color="#000000"
                    fontSize="16px"
                    fontWeight="400"
                    padding="12px 25px"
                    height="44px"
                    bgColor="transparent"
                    brColor="#DDDDDD"
                    fontFamily="Akkurat"
                    onClick={() => navigate(superAdminAddCategory(locale))}
                  >
                    <img
                      src={AddBlackColor}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                      className="me-2"
                    />
                    <FormattedMessage id="Add Category" />
                  </ReusableButton>
                </div>
              </Row>

              <Row className="m-0 p-0">
                <Col md={12}>
                  <ScrollableTabs
                    tabs={tabs}
                    onTabChange={handleTabChange}
                    initialTab={0}
                  />
                </Col>
              </Row>
              {selectedTab === 0 && (
                <>
                  <Row className="m-0 p-0 mt-4">
                    <div className="flex justify-between items-center">
                      <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
                        {'Hair'}
                      </h2>
                      <Stack
                        direction="horizontal"
                        gap={4}
                        className="items-center "
                      >
                        <ReusableButton
                          fullWidth={false}
                          variant="outlined"
                          color="#000000"
                          fontSize="16px"
                          fontWeight="400"
                          padding="12px 25px"
                          height="44px"
                          bgColor="transparent"
                          brColor="#DDDDDD"
                          fontFamily="Akkurat"
                          onClick={() =>
                            navigate(superAdminAddSubCategory(locale))
                          }
                        >
                          <img
                            src={AddBlackColor}
                            alt="icon"
                            style={{ height: '24px', width: '24px' }}
                            className="me-2"
                          />
                          <FormattedMessage id="Add Sub-Category" />
                        </ReusableButton>

                        <ReusableButton
                          fullWidth={false}
                          variant="outlined"
                          color="#000000"
                          fontSize="16px"
                          fontWeight="400"
                          padding="12px 25px"
                          height="44px"
                          bgColor="transparent"
                          brColor="#DDDDDD"
                          fontFamily="Akkurat"
                          onClick={() =>
                            navigate(superAdminEditCategory(locale))
                          }
                        >
                          <img
                            src={EditIcon}
                            alt="icon"
                            style={{ height: '24px', width: '24px' }}
                            className="me-2"
                          />
                          <FormattedMessage id="Edit category" />
                        </ReusableButton>
                      </Stack>
                    </div>
                  </Row>

                  <Row className="m-0 p-0 mt-2">
                    <ServiceList
                      services={services}
                      onEdit={() => handleEditService()}
                      onDelete={() => handleDeleteService()}
                      onAddSubService={() => handleAddSubService()}
                    />
                  </Row>
                </>
              )}
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default Categories;
