import React, { useContext, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import 'Pages/SuperAdmin/Dashboard/dashboard.scss';
import rightArrow from 'assets/images/Dashboard/arrow-right.png';
import addIcon from 'assets/images/Dashboard/add.png';
import mail from 'assets/images/Dashboard/mail.png';
import call from 'assets/images/Dashboard/call.png';
import trash from 'assets/images/Dashboard/trash.png';
import ReusableTabs from 'components/common/Tabs';
import { FormattedMessage, useIntl } from 'react-intl';
import ReusableButton from 'components/common/Button';
import {
  superAdminAddProvider,
  superAdminCompanyProviderDetails,
  superAdminNewRequestProvider,
  superAdminOnHoldProvider,
  superAdminSalonProviderDetails,
  superAdminSpecialistProviderDetails,
} from 'routes/AppUrls';

const SuperAdminProvider = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const [tab, setTab] = useState(1);

  const tabData = [
    { label: intl.formatMessage({ id: 'All Providers' }) },
    { label: intl.formatMessage({ id: 'New Requests' }) },
    { label: intl.formatMessage({ id: 'On Hold' }) },
  ];

  const handleTabChange = (val) => {
    setTab(val);
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
          <FormattedMessage id="Provider" />{' '}
        </h2>

        <Row className="m-0 p-0 mt-3 justify-between">
          <Col md={12} lg={8} xl={8} xxl={6} className="m-0 p-0">
            <ReusableTabs
              tabs={tabData}
              defaultTab={1}
              currentTab={(val) => handleTabChange(val)}
            />
          </Col>
          <Col md={12} lg={2} xl={2} xxl={2} className="m-0 p-0">
            <ReusableButton
              fullWidth={true}
              variant="contained"
              color="#F5F5F5"
              fontSize="16px"
              fontWeight="400"
              padding="12px"
              height="48px"
              bgColor="#292D32"
              fontFamily="Akkurat"
              onClick={() => navigate(superAdminAddProvider(locale))}
            >
              <img
                src={addIcon}
                alt="icon"
                style={{ height: '24px', width: '24px' }}
                className="me-2"
              />
              <FormattedMessage id="Add a Provider" />
            </ReusableButton>
          </Col>
        </Row>

        {tab === 1 && (
          <>
            <Row className="mt-3 listHeader m-0 p-0 flex items-center">
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Providers" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Serving Location" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Contact Info" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Joined On" />
                </p>
              </Col>
              <Col lg={4} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Actions" />
                </p>
              </Col>
            </Row>

            <Row className="bc-FFFFFF m-0 p-0">
              {[1, 2, 3, 4, 5, 6, 7, 8].map((key, index) => (
                <Row className="m-0 p-0 flex items-center listAppointment">
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {'Christine Brooks'}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'00001'}
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                      {'203A Spanish Tn Rd, Kingston'}
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <img
                          src={mail}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'random123@mail'}
                        </p>
                      </Stack>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center mt-2"
                      >
                        <img
                          src={call}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'9874456321'}
                        </p>
                      </Stack>
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                      {'23 Nov 2019'}
                    </p>
                  </Col>
                  <Col lg={4} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="items-center cursor-pointer"
                      onClick={() =>
                        index === 0
                          ? navigate(
                              superAdminSpecialistProviderDetails(locale)
                            )
                          : index === 1
                            ? navigate(superAdminSalonProviderDetails(locale))
                            : navigate(superAdminCompanyProviderDetails(locale))
                      }
                    >
                      <img
                        src={trash}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                      />
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                          <FormattedMessage id="View Details" />
                        </p>
                        <img
                          src={rightArrow}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                      </Stack>
                    </Stack>
                  </Col>
                </Row>
              ))}
            </Row>
          </>
        )}

        {tab === 2 && (
          <>
            <Row className="mt-3 listHeader m-0 p-0 flex items-center">
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Providers" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Serving Location" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Contact Info" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Joined On" />
                </p>
              </Col>
              <Col lg={4} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Actions" />
                </p>
              </Col>
            </Row>

            <Row className="bc-FFFFFF m-0 p-0">
              {[1, 2, 3, 4, 5].map((key, index) => (
                <Row className="m-0 p-0 flex items-center listAppointment">
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {'Christine Brooks'}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'00001'}
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                      {'203A Spanish Tn Rd, Kingston'}
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <img
                          src={mail}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'random123@mail'}
                        </p>
                      </Stack>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center mt-2"
                      >
                        <img
                          src={call}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'9874456321'}
                        </p>
                      </Stack>
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                      {'23 Nov 2019'}
                    </p>
                  </Col>
                  <Col lg={4} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="items-center "
                    >
                      <img
                        src={trash}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                      />
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center cursor-pointer"
                        onClick={() =>
                          navigate(superAdminNewRequestProvider(locale))
                        }
                      >
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                          <FormattedMessage id="View Details" />
                        </p>
                        <img
                          src={rightArrow}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                      </Stack>
                    </Stack>
                  </Col>
                </Row>
              ))}
            </Row>
          </>
        )}

        {tab === 3 && (
          <>
            <Row className="mt-3 listHeader m-0 p-0 flex items-center">
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Providers" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Serving Location" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Contact Info" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Joined On" />
                </p>
              </Col>
              <Col lg={4} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Actions" />
                </p>
              </Col>
            </Row>

            <Row className="bc-FFFFFF m-0 p-0">
              {[1, 2, 3, 4, 5, 6, 7, 8].map((key, index) => (
                <Row className="m-0 p-0 flex items-center listAppointment">
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {'Christine Brooks'}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'00001'}
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat mt-1">
                      {'203A Spanish Tn Rd, Kingston'}
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <img
                          src={mail}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'random123@mail'}
                        </p>
                      </Stack>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center mt-2"
                      >
                        <img
                          src={call}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'9874456321'}
                        </p>
                      </Stack>
                    </p>
                  </Col>
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                      {'23 Nov 2019'}
                    </p>
                  </Col>
                  <Col lg={4} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="items-center "
                    >
                      <img
                        src={trash}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                      />
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center cursor-pointer"
                        onClick={() =>
                          navigate(superAdminOnHoldProvider(locale))
                        }
                      >
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                          <FormattedMessage id="View Details" />
                        </p>
                        <img
                          src={rightArrow}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                      </Stack>
                    </Stack>
                  </Col>
                </Row>
              ))}
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default SuperAdminProvider;
