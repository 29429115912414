import { configureStore } from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Use localStorage as the storage engine
import { thunk } from 'redux-thunk'; // Redux Thunk middleware
import rootReducer from 'store/root-reducer'; // Your combined reducers
import { logger } from 'redux-logger';

// Persist configuration
const persistConfig = {
  key: 'root', // Key for the persisted state
  storage, // Use localStorage
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(thunk, logger),
});

// Create the persistor
export const persistor = persistStore(store);
export default store;
