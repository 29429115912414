import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import ServiceLogo from 'assets/images/Dashboard/Logo.png';
import ReusableButton from 'components/common/Button';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { superAdminCampaigns } from 'routes/AppUrls';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import leftArrow from 'assets/images/Dashboard/arrow-left.png';
import ReusableTabs from 'components/common/Tabs';
import LinkBaseIcon from 'assets/images/Campaigns/LinkSimpleLight.png';
import AssociationBaseIcon from 'assets/images/Campaigns/ShareFatLight.png';
import LinkBaseColorIcon from 'assets/images/Campaigns/LinkSimpleWhite.png';
import AssociationBaseColorIcon from 'assets/images/Campaigns/ShareFatWhite.png';
import CalendarBlankIcon from 'assets/images/Campaigns/CalendarBlank.png';
import SelectInput from 'components/common/Input/SelectInput';

const AddCampaign = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const [tab, setTab] = useState(1);
  const [selectedDay, setSelectedDay] = useState([]);

  const tabData = [
    {
      label: intl.formatMessage({ id: 'Link Based' }),
      icon: LinkBaseIcon,
      colorIcon: LinkBaseColorIcon,
    },
    {
      label: intl.formatMessage({ id: 'Association Based' }),
      icon: AssociationBaseIcon,
      colorIcon: AssociationBaseColorIcon,
    },
  ];

  const [formValue, setFormValue] = useState({
    categoryNameEn: '',
    productsusedEn: '',
    benefitsEn: '',
    procedureEn: '',
    precautionsEn: '',
    thingstoknowEn: '',
    aftercaretipsEn: '',
    categoryNameAr: '',
    productsusedAr: '',
    benefitsAr: '',
    procedureAr: '',
    precautionsAr: '',
    thingstoknowAr: '',
    aftercaretipsAr: '',
  });

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Campaigns" />,
      Link: superAdminCampaigns(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Add Campaign" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  const handleTabChange = (val) => {
    setTab(val);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const weekdayList = [
    { name: 'Sunday', short: 'Su' },
    { name: 'Monday', short: 'Mo' },
    { name: 'Tuesday', short: 'Tu' },
    { name: 'Wednesday', short: 'We' },
    { name: 'Thrusday', short: 'Th' },
    { name: 'Friday', short: 'Fr' },
    { name: 'Saturday', short: 'Sa' },
  ];

  const handleWeekdaySelect = (value) => {
    setSelectedDay((prev) => {
      if (prev.includes(value.short)) {
        return prev.filter((day) => day !== value.short);
      } else {
        return [...prev, value.short];
      }
    });
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <div className="flex justify-between items-center">
            <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
              <Stack direction="horizontal" gap={2} className="items-center">
                <img
                  src={leftArrow}
                  alt="icon"
                  style={{ height: '32px', width: '32px' }}
                  className="cursor-pointer"
                  onClick={() => navigate(superAdminCampaigns(locale))}
                />
                <p className="p-0 m-0">
                  <FormattedMessage id="Add Campaign" />
                </p>
              </Stack>
            </h2>
            <img
              src={ServiceLogo}
              alt={'logo'}
              style={{ height: '64px', width: '96px' }}
            />
          </div>

          <Col sm={12} md={6} className="">
            <Col sm={12} md={12} className="pt-15 mt-3">
              <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                <FormattedMessage id="Campaign Type" />
              </p>
              <div className="mt-2">
                <ReusableTabs
                  hoverDisable={true}
                  image={true}
                  tabs={tabData}
                  defaultTab={1}
                  currentTab={(val) => handleTabChange(val)}
                  hasBorder={'#DDDDDD'}
                />
              </div>
            </Col>

            <Col xs={12} md={12} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Campaign Name" />}
                placeholder={intl.formatMessage({
                  id: 'Campaign Name',
                })}
                name="campaignName"
                type="text"
                value={formValue?.campaignName}
                onChange={handleInputChange}
                // errorText={isError && isError?.answerEn?.error && isError?.answerEn?.errorMessage}
              />
            </Col>

            <Col xs={12} md={12} className="mt-3">
              <SelectInput
                label={<FormattedMessage id="Enter a link" />}
                placeholder={intl.formatMessage({ id: 'Enter a link' })}
                name={'link'}
                value={formValue?.link}
                // data={countryList?.data}
                onChange={(e) => handleInputChange(e)}
                // errorText={isError && isError.country.error && isError.country.errorMessage}
              />
            </Col>

            <Col xs={12} md={12} className="pt-15 mt-3">
              <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                <FormattedMessage id="Validity" />
              </p>
              <Row className="p-0 m-0 mt-2 imageBorderWithoutDashed flex items-center">
                <Col xs={12} md={6}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={CalendarBlankIcon}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat">
                      <FormattedMessage id="From" />
                    </p>
                  </Stack>
                </Col>
                <Col xs={12} md={6}>
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center"
                  >
                    <img
                      src={CalendarBlankIcon}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-9A9A9A fw-400 ff-Akkurat">
                      <FormattedMessage id="To" />
                    </p>
                  </Stack>
                </Col>
              </Row>
            </Col>

            <Col xs={12} md={12} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Description" />}
                placeholder={intl.formatMessage({ id: 'Description' })}
                height="160"
                multiline
                name="description"
                value={formValue?.description}
                onChange={(e) => handleChange(e)}
                // errorText={isError && isError?.answerEn?.error && isError?.answerEn?.errorMessage}
              />
            </Col>

            {tab === 2 && (
              <Col xs={12} md={12} className="mt-3">
                <SelectInput
                  label={<FormattedMessage id="Select Associations" />}
                  placeholder={intl.formatMessage({
                    id: 'Select Associations',
                  })}
                  name={'selectAssociations'}
                  value={formValue?.selectAssociations}
                  // data={countryList?.data}
                  onChange={(e) => handleInputChange(e)}
                  // errorText={isError && isError.country.error && isError.country.errorMessage}
                />
              </Col>
            )}
          </Col>

          <Col sm={12} md={6} className="">
            <Col xs={12} md={12} className="mt-3">
              <SelectInput
                label={<FormattedMessage id="Country" />}
                placeholder={intl.formatMessage({ id: 'Country' })}
                name={'country'}
                value={formValue?.country}
                // data={countryList?.data}
                onChange={(e) => handleInputChange(e)}
                // errorText={isError && isError.country.error && isError.country.errorMessage}
              />
            </Col>

            <Col xs={12} md={12} className="mt-3">
              <SelectInput
                label={<FormattedMessage id="City" />}
                placeholder={intl.formatMessage({ id: 'City' })}
                name={'city'}
                value={formValue?.city}
                // data={countryList?.data}
                onChange={(e) => handleInputChange(e)}
                // errorText={isError && isError.country.error && isError.country.errorMessage}
              />
            </Col>

            <Col xs={12} md={12} className="pt-15 mt-3">
              <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                <FormattedMessage id="Active Days" />
              </p>
              <div className="mt-2  gap-3 flex text-center flex-wrap">
                {weekdayList.map((item, index) => (
                  <div
                    className={`weekdaysCampaign ${selectedDay.includes(item?.short) ? 'bc-292D32' : 'bc-FFFFFF'} cursor-pointer`}
                    onClick={() => handleWeekdaySelect(item)}
                  >
                    <p
                      className={`p-0 m-0 fs-14 ${selectedDay.includes(item?.short) ? 'fc-FFFFFF' : 'fc-9A9A9A'}  fw-500 ff-Akkurat`}
                    >
                      {item?.short}
                    </p>
                  </div>
                ))}
              </div>
            </Col>

            <Col xs={12} md={12} className="pt-15 mt-3">
              <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                <FormattedMessage id="Banner Image" />
              </p>
              <div className="mt-2 bannerImage flex justify-center items-center ">
                <div>
                  <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                    <FormattedMessage id="Drag & Drop or Browse" />
                  </p>
                  <p className="p-0 m-0 fs-12 mt-2 fc-292D32 fw-400 ff-Akkurat_Bold">
                    <FormattedMessage id="Only png & jpg formats are supported" />
                  </p>
                  <p className="p-0 m-0 fs-12 mt-2 fc-292D32 fw-400 ff-Akkurat">
                    <FormattedMessage id="Minimum image resolution should be 360x300" />
                  </p>
                </div>
              </div>
            </Col>
          </Col>

          <Row className="mt-4 items-end m-0 p-0">
            <div className="flex justify-end">
              <Stack direction="horizontal" gap={3} className="items-center">
                <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#292D32"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 25px"
                  height="44px"
                  bgColor="transparent"
                  fontFamily="Akkurat"
                  onClick={() => navigate(superAdminCampaigns(locale))}
                >
                  <FormattedMessage id="Cancel" />
                </ReusableButton>

                <ReusableButton
                  fullWidth={false}
                  variant="contained"
                  color="#F5F5F5"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 25px"
                  height="44px"
                  bgColor="#292D32"
                  fontFamily="Akkurat"
                >
                  <FormattedMessage id="Save & Add" />
                </ReusableButton>
              </Stack>
            </div>
          </Row>
        </Row>
      </Col>
    </Row>
  );
};

export default AddCampaign;
