import React, { useContext, useEffect, useState, useRef } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { superAdminAddProvider, superAdminProvider } from 'routes/AppUrls';
import LabelWithInput from 'components/common/Input/LabelWithInput';
import SelectInput from 'components/common/Input/SelectInput';
import PhoneNumberInput from 'components/common/Input/PhoneNumberInput';
import AddBlackColor from 'assets/images/Dashboard/addBlackColor.png';
import image1 from 'assets/images/provider/image1.png';
import crossIcon from 'assets/images/provider/crossIcon.png';
import ServiceLogo from 'assets/images/Dashboard/Logo.png';
import ReusableButton from 'components/common/Button';
import leftArrow from 'assets/images/Dashboard/arrow-left.png';
import InputAdornment from '@mui/material/InputAdornment';

const AddBranch = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const branchImageRef = useRef();
  const [branchImages, setBranchImages] = useState([]);

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isError, setIsError] = useState(null);
  const phoneNumberInputRef = useRef(null);

  const [formValue, setFormValue] = useState({
    country: '',
    countryCode: 'qa',
    countryCodeAlternateNumber: 'qa',
    city: '',
    phoneNumber: '',
    alternatePhoneNumber: '',
    addressArea: '',
    specialistName: '',
    email: '',
    alternateEmail: '',
    about: '',
  });

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Providers" />,
      Link: superAdminProvider(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Add Branch" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  const handleCountryCodeChange = (value, name) => {
    if (name === 'alternatePhoneNumber') {
      setFormValue({ ...formValue, countryCodeAlternateNumber: value });
    } else if (name === 'phoneNumber') {
      setFormValue({ ...formValue, countryCode: value });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue({ ...formValue, [name]: value });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setFormValue({ ...formValue, [name]: value });
  };

  const handleBranchImageChange = (e) => {
    const file = e.target.files[0];
    setBranchImages([...branchImages, URL.createObjectURL(file)]);
    if (file) {
      let Data = new FormData();
      Data.append('profile_image', file);
    }
  };

  const handleBranchImageUpload = () => {
    branchImageRef.current.click();
  };

  const handleDeleteBranchImage = (data, index) => {
    const filterImages = branchImages?.filter((value) => value !== data);
    setBranchImages(filterImages);
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <div className="flex justify-between items-center">
            <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
              <Stack direction="horizontal" gap={2} className="items-center">
                <img
                  src={leftArrow}
                  alt="icon"
                  style={{ height: '32px', width: '32px' }}
                  className="cursor-pointer"
                  onClick={() => navigate(superAdminProvider(locale))}
                />
                <p className="p-0 m-0">
                  <FormattedMessage id="Add Branch" />
                </p>
              </Stack>
            </h2>
            <img
              src={ServiceLogo}
              alt={'logo'}
              style={{ height: '64px', width: '96px' }}
            />
          </div>

          <div className="mt-4">
            <p className="p-0 m-0 fc-292D32 fs-18 fw-400 ff-Akkurat_Bold">
              {'Branch Details'}
            </p>
          </div>

          <Row className="m-0 p-0">
            <Col xs={12} md={6} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Search business name" />}
                placeholder={intl.formatMessage({ id: 'Search business name' })}
                name="searchBussinessName"
                type="text"
                value={formValue?.searchBussinessName}
                onChange={handleChange}
                // errorText={
                //     isError && isError.email.error && isError.email.errorMessage
                // }
              />
            </Col>

            <Col xs={12} md={6} className=""></Col>

            <Col xs={12} md={6} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Address of the serving area" />}
                placeholder={intl.formatMessage({
                  id: 'Address of the serving area',
                })}
                name="addressArea"
                type="text"
                value={formValue?.addressArea}
                onChange={handleChange}
                // errorText={
                //     isError && isError.email.error && isError.email.errorMessage
                // }
              />
            </Col>

            <Col xs={12} md={3} className="mt-3">
              <SelectInput
                label={<FormattedMessage id="Country" />}
                placeholder={intl.formatMessage({ id: 'Country' })}
                name={'country'}
                value={formValue?.country}
                // data={countryList?.data}
                onChange={(e) => handleInputChange(e)}
                // errorText={isError && isError.country.error && isError.country.errorMessage}
              />
            </Col>

            <Col xs={12} md={3} className="mt-3">
              <SelectInput
                label={<FormattedMessage id="City" />}
                placeholder={intl.formatMessage({ id: 'City' })}
                name={'city'}
                value={formValue?.city}
                // data={countryList?.data}
                onChange={(e) => handleInputChange(e)}
                // errorText={isError && isError.country.error && isError.country.errorMessage}
              />
            </Col>

            <Col xs={12} md={6} className="mt-3">
              <PhoneNumberInput
                label="Contact Number"
                country={formValue?.countryCode}
                value={formValue?.countryCode}
                name={'phoneNumber'}
                onCountryCodeChange={(e) =>
                  handleCountryCodeChange(e, 'phoneNumber')
                }
                onPhoneNumberChange={handleChange}
                phoneNumber={formValue.phoneNumber}
                phoneNumberRef={phoneNumberInputRef}
                isPhoneNumberValid={isPhoneNumberValid}
                isError={isError}
                suffixText="verify"
                errorMessage="Invalid phone number for selected country code"
              />
            </Col>

            <Col xs={12} md={6} className="mt-3">
              <PhoneNumberInput
                label="Alternate Contact Number"
                country={formValue?.countryCodeAlternateNumber}
                value={formValue?.countryCodeAlternateNumber}
                name={'alternatePhoneNumber'}
                onCountryCodeChange={(e) =>
                  handleCountryCodeChange(e, 'alternatePhoneNumber')
                }
                onPhoneNumberChange={handleChange}
                phoneNumber={formValue.alternatePhoneNumber}
                phoneNumberRef={phoneNumberInputRef}
                isPhoneNumberValid={isPhoneNumberValid}
                isError={isError}
                suffixText="verify"
                errorMessage="Invalid phone number for selected country code"
              />
            </Col>

            <Col xs={12} md={6} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Email ID" />}
                placeholder={intl.formatMessage({ id: 'Email ID' })}
                name="email"
                type="text"
                value={formValue?.email}
                onChange={handleChange}
                // errorText={
                //     isError && isError.email.error && isError.email.errorMessage
                // }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                        {'verify'}
                      </p>
                    </InputAdornment>
                  ),
                }}
              />
            </Col>

            <Col xs={12} md={6} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Alternate Email ID" />}
                placeholder={intl.formatMessage({ id: 'Alternate Email ID' })}
                name="alternateEmail"
                type="text"
                value={formValue?.alternateEmail}
                onChange={handleChange}
                // errorText={
                //     isError && isError.email.error && isError.email.errorMessage
                // }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                        {'verify'}
                      </p>
                    </InputAdornment>
                  ),
                }}
              />
            </Col>

            <Col xs={12} md={12} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="About/Information" />}
                placeholder={intl.formatMessage({ id: 'About/Information' })}
                height="100"
                multiline
                name="about"
                value={formValue?.about}
                onChange={(e) => handleChange(e)}
                // errorText={isError && isError?.answerEn?.error && isError?.answerEn?.errorMessage}
              />
            </Col>

            <Col xs={12} md={12} className="mt-4">
              <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                <FormattedMessage id="Branch Manager" />
              </p>
            </Col>

            <Col xs={12} md={6} className="mt-1">
              <LabelWithInput
                label={<FormattedMessage id="Name" />}
                placeholder={intl.formatMessage({ id: 'Name' })}
                name="branchName"
                type="text"
                value={formValue?.branchName}
                onChange={handleChange}
                // errorText={
                //     isError && isError.email.error && isError.email.errorMessage
                // }
              />
            </Col>

            <Col xs={12} md={6} className=""></Col>

            <Col xs={12} md={6} className="mt-3">
              <PhoneNumberInput
                label="Contact Number"
                country={formValue?.countryCodeAlternateNumber}
                value={formValue?.countryCodeAlternateNumber}
                name={'branchPhoneNumber'}
                onCountryCodeChange={(e) =>
                  handleCountryCodeChange(e, 'branchPhoneNumber')
                }
                onPhoneNumberChange={handleChange}
                phoneNumber={formValue.alternatePhoneNumber}
                phoneNumberRef={phoneNumberInputRef}
                isPhoneNumberValid={isPhoneNumberValid}
                isError={isError}
                suffixText="verify"
                errorMessage="Invalid phone number for selected country code"
              />
            </Col>

            <Col xs={12} md={6} className="mt-3">
              <LabelWithInput
                label={<FormattedMessage id="Email ID" />}
                placeholder={intl.formatMessage({ id: 'Email ID' })}
                name="branchEmail"
                type="text"
                value={formValue?.branchEmail}
                onChange={handleChange}
                // errorText={
                //     isError && isError.email.error && isError.email.errorMessage
                // }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                        {'verify'}
                      </p>
                    </InputAdornment>
                  ),
                }}
              />
            </Col>

            <Col xs={12} md={12} className="mt-3">
              <div className="flex justify-between items-center">
                <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                  <FormattedMessage id="Images" />
                </p>
                {branchImages?.length > 0 && (
                  <ReusableButton
                    fullWidth={false}
                    variant="outlined"
                    color="#292D32"
                    fontSize="16px"
                    fontWeight="400"
                    padding="12px 25px"
                    height="44px"
                    bgColor="transparent"
                    fontFamily="Akkurat"
                    onClick={handleBranchImageUpload}
                  >
                    <img
                      src={AddBlackColor}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                      className="me-2"
                    />
                    <FormattedMessage id="Add Images" />
                  </ReusableButton>
                )}
              </div>

              <input
                ref={branchImageRef}
                type="file"
                accept=".png, .jpg, .jpeg"
                disabled={false}
                style={{ display: 'none' }}
                onChange={handleBranchImageChange}
              />

              {branchImages?.length <= 0 ? (
                <div className="mt-2 bussinessImages flex justify-center align-center">
                  <Stack
                    direction="horizontal"
                    gap={2}
                    className="items-center cursor-pointer"
                    onClick={handleBranchImageUpload}
                  >
                    <img
                      src={AddBlackColor}
                      alt="icon"
                      style={{ height: '24px', width: '24px' }}
                    />
                    <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat_Bold">
                      <FormattedMessage id="AddImageEn" />
                    </p>
                  </Stack>
                </div>
              ) : (
                <div className="flex flex-wrap gap-2 mt-3">
                  {branchImages.map((key, index) => (
                    <div className="relative">
                      <img
                        src={key}
                        alt="image"
                        style={{ height: '122px', width: '246px' }}
                      />
                      <img
                        src={crossIcon}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                        className="absolute top-2 right-2 cursor-pointer"
                        onClick={() => handleDeleteBranchImage(key, index)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </Col>
          </Row>

          <Row className="mt-4 items-end m-0 p-0">
            <div className="flex justify-end">
              <Stack direction="horizontal" gap={3} className="items-center">
                <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#292D32"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 25px"
                  height="44px"
                  bgColor="transparent"
                  fontFamily="Akkurat"
                  onClick={() => navigate(superAdminAddProvider(locale))}
                >
                  <FormattedMessage id="Cancel" />
                </ReusableButton>

                <ReusableButton
                  fullWidth={false}
                  variant="contained"
                  color="#F5F5F5"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 25px"
                  height="44px"
                  bgColor="#292D32"
                  fontFamily="Akkurat"
                >
                  <FormattedMessage id="Next" />
                </ReusableButton>
              </Stack>
            </div>
          </Row>
        </Row>
      </Col>
    </Row>
  );
};

export default AddBranch;
