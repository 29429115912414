import React, { useContext, useEffect, useState, useRef } from 'react';
import { Row, Col, Stack, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { superAdminProvider } from 'routes/AppUrls';
import ReusableTabs from 'components/common/Tabs';
import ProfileIcon from 'assets/images/navbar/profileIcon.png';
import SwitchInput from 'components/common/Input/SwitchInput';
import callIcon from 'assets/images/Dashboard/call.png';
import mailIcon from 'assets/images/Dashboard/mail.png';
import trash from 'assets/images/Dashboard/trash.png';
import rightArrow from 'assets/images/Dashboard/arrow-right.png';
import visaCardIcon from 'assets/images/provider/visa.png';
import masterCardIcon from 'assets/images/provider/masterCard.png';
import americanCardIcon from 'assets/images/provider/americanExpress.png';
import cashCardIcon from 'assets/images/provider/cash.png';
import PhotoImage from 'assets/images/provider/image4.png';
import Provider from 'assets/images/sidebar/ProviderColor.png';
import Avtar from 'assets/images/Dashboard/avtar.png';
import HouseIcon from 'assets/images/provider/HouseLine.png';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Frame1 from 'assets/images/Tab/Frame1.png';
import Frame2 from 'assets/images/Tab/Frame2.png';
import Frame3 from 'assets/images/Tab/Frame3.png';
import Frame4 from 'assets/images/Tab/Frame4.png';
import Frame5 from 'assets/images/Tab/Frame5.png';
import Frame6 from 'assets/images/Tab/Frame6.png';
import Frame7 from 'assets/images/Tab/Frame7.png';
import ScrollableTabs from 'components/common/SrollableTabs';
import ServiceList from 'Pages/SuperAdmin/Categories/seviceList';
import MiniCalendar from 'Pages/SuperAdmin/MiniCalendar';

const CompanyDetails = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);
  const [tab, setTab] = useState(1);
  const [scrollTab, setScrollTab] = useState(0);
  const specialistImageFileInputRef = useRef();
  const [specialistImageFile, setSpecialistImageFile] = useState(null);
  const [checked, setChecked] = useState(false);

  const tabData = [
    { label: intl.formatMessage({ id: 'About' }) },
    { label: intl.formatMessage({ id: 'Services' }) },
    { label: intl.formatMessage({ id: 'Specialists' }) },
    { label: intl.formatMessage({ id: 'Branches' }) },
    { label: intl.formatMessage({ id: 'Reviews' }) },
  ];

  const tabs = [
    { label: 'Hair', icon: Frame7 },
    { label: 'Massage', icon: Frame5 },
    { label: 'Skin & nails', icon: Frame1 },
    { label: 'Makeup', icon: Frame6 },
    { label: 'Brow & lash', icon: Frame2 },
    { label: 'Bridal', icon: Frame3 },
    { label: 'Cosmetic clinics', icon: Frame4 },
    { label: 'Tab Eight', icon: Frame6 },
  ];

  const [services, setServices] = React.useState([
    {
      id: 1,
      name: 'Haircut',
      price: 20,
      currency: 'SAR',
      time: 30,
      subServices: [],
    },
    {
      id: 2,
      name: 'Hair Treatment',
      price: 20,
      currency: 'SAR',
      time: 30,
      subServices: [
        { id: 21, name: 'Roots', price: 40, currency: 'SAR', time: 60 },
        { id: 22, name: 'Highlight', price: 40, currency: 'SAR', time: 60 },
        { id: 23, name: 'Highlight5', price: 45, currency: 'SAR', time: 65 },
      ],
    },
    {
      id: 3,
      name: 'Hair Color',
      price: 40,
      currency: 'SAR',
      time: 60,
      subServices: [{ id: 31, name: 'Single Color', price: 40, time: 60 }],
    },
  ]);

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Providers" />,
      Link: superAdminProvider(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Provider Details" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  const handleTabChange = (val) => {
    setTab(val);
  };

  const handleScrollTabChange = (newValue) => {
    setScrollTab(newValue);
  };

  const handleSpecialistImageFileChange = (e) => {
    const file = e.target.files[0];
    setSpecialistImageFile(URL.createObjectURL(file));
    if (file) {
      let Data = new FormData();
      Data.append('profile_image', file);
    }
  };

  const handleSpecialistImageUpload = () => {
    specialistImageFileInputRef.current.click();
  };

  const handleSwitchChange = (event) => {
    setChecked(!checked);
  };

  const ImageList = (images, maxVisible) => {
    const visibleImages = images.slice(0, maxVisible);
    const remainingCount = images.length - maxVisible;

    return (
      <div className="flex flex-wrap mt-3 gap-3 p-0">
        {visibleImages.map((image, index) => (
          <div className="image-item relative" key={index}>
            <img
              src={PhotoImage}
              alt={`Image ${index + 1}`}
              style={{ height: '124px', width: '124px' }}
            />
            {index === maxVisible - 1 && remainingCount > 0 && (
              <div className="overlay">
                <span>+{remainingCount}</span>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <input
            ref={specialistImageFileInputRef}
            type="file"
            accept=".png, .jpg, .jpeg"
            disabled={false}
            style={{ display: 'none' }}
            onChange={handleSpecialistImageFileChange}
          />

          <div className="mt-4 flex justify-between items-center">
            <Stack direction="horizontal" gap={4} className="items-center">
              <div className="AvtarImageIcon">
                {specialistImageFile && (
                  <img
                    src={specialistImageFile}
                    alt={'icon'}
                    style={{
                      height: '88px',
                      width: '88px',
                      borderRadius: '50%',
                    }}
                  />
                )}
                <img
                  src={ProfileIcon}
                  alt="icon"
                  style={{ height: '32px', width: '32px' }}
                  className="profileIcon"
                  onClick={handleSpecialistImageUpload}
                />
              </div>
              <div>
                <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                  Company Name
                </p>
                <p className="p-0 m-0 fs-16 fc-A4AAB2 fw-400 ff-Akkurat">
                  Owner: Christine Brooks
                </p>
              </div>
            </Stack>

            <div>
              <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                Headquarters
              </p>
              <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat">
                089 Kutch Green Apt. 448
              </p>
            </div>

            <div className="">
              <SwitchInput
                label={'Test User'}
                checked={checked}
                onChange={(e) => handleSwitchChange(e)}
                color="secondary"
              />
            </div>
          </div>

          <Col xs={12} md={3} className="mt-3">
            <Stack direction="horizontal" gap={2} className="items-center">
              <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                Account Manager
              </p>
            </Stack>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat mt-1">
              {'Gilbert Johnson'}
            </p>
          </Col>

          <Col xs={12} md={3} className="mt-3">
            <Stack direction="horizontal" gap={2} className="items-center">
              <img
                src={callIcon}
                alt="icon"
                style={{ height: '20px', width: '20px' }}
              />
              <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
                Phone number
              </p>
            </Stack>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat mt-1">
              {'+01 9874456321'}
            </p>
          </Col>

          <Col xs={12} md={3} className="mt-3">
            <Stack direction="horizontal" gap={2} className="items-center">
              <img
                src={mailIcon}
                alt="icon"
                style={{ height: '20px', width: '20px' }}
              />
              <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">Email</p>
            </Stack>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat mt-1">
              {'random123@mail'}
            </p>
          </Col>

          <Col xs={12} md={2} className="mt-3 text-center">
            <p className="p-0 m-0 fs-14 fc-A4AAB2 fw-400 ff-Akkurat">
              Active since:
            </p>
            <p className="p-0 m-0 fs-16 fc-292D32 fw-400 ff-Akkurat mt-1">
              {'23 Nov 2019'}
            </p>
          </Col>
        </Row>

        <Row className="mt-3 pt-1">
          <Col xs={12} md={10}>
            <ReusableTabs
              tabs={tabData}
              defaultTab={1}
              currentTab={(val) => handleTabChange(val)}
            />
          </Col>
        </Row>

        {tab === 1 && (
          <>
            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              <h5 className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                {'Information'}
              </h5>
              <p className="p-0 m-0 fs-16 fc-A2A2A2 fw-400 ff-Akkurat mt-3">
                {
                  'Lorem ipsum dolor sit amet consectetur. Suspendisse amet mauris vitae etiam sit maecenas vel accumsan nec. Cras commodo egestas ridiculus velit et consectetur accumsan. Neque luctus tristique vestibulum eu. Sit sed quis et risus morbi. Volutpat ultrices tempor purus in purus etiam massa nullam. In commodo elit ac tincidunt. Fermentum diam magna pellentesque penatibus vel nec integer mauri.'
                }
              </p>
            </Row>

            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              <h5 className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                {'Payment Methods'}
              </h5>
              <div className="flex flex-wrap mt-3 gap-3  p-0">
                <img
                  src={visaCardIcon}
                  alt="icon"
                  style={{ height: '40px', width: '73px' }}
                />
                <img
                  src={masterCardIcon}
                  alt="icon"
                  style={{ height: '40px', width: '73px' }}
                />
                <img
                  src={americanCardIcon}
                  alt="icon"
                  style={{ height: '40px', width: '73px' }}
                />
                <img
                  src={cashCardIcon}
                  alt="icon"
                  style={{ height: '40px', width: '73px' }}
                />
              </div>
            </Row>

            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              <h5 className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                {'Ambience'}
              </h5>
              <div className="flex flex-wrap mt-2 gap-3  p-0">
                {[1, 2, 3, 4].map((k, i) => (
                  <Badge
                    pill
                    className={'d-flex items-center text-center ambiencePill'}
                  >
                    <FormattedMessage id="Cozy" />
                  </Badge>
                ))}
              </div>
            </Row>

            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              <h5 className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                {'Photos'}
              </h5>

              <div className="p-0">
                {/* {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((image, index) => (
                  <>
                    <img
                      src={PhotoImage}
                      alt="icon"
                      style={{ height: '124px', width: '124px' }}
                    />
                  </>
                ))} */}
                {ImageList(
                  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                  11
                )}
              </div>
            </Row>
          </>
        )}

        {tab === 2 && (
          <>
            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              <Row className="m-0 p-0">
                <Col md={12}>
                  <ScrollableTabs
                    tabs={tabs}
                    onTabChange={handleScrollTabChange}
                    initialTab={0}
                  />
                </Col>
              </Row>

              {scrollTab === 0 && (
                <>
                  <Row className="m-0 p-0 mt-2">
                    <ServiceList services={services} onEdit={false} />
                  </Row>
                </>
              )}
            </Row>
          </>
        )}

        {tab === 3 && (
          <>
            <Row className="mt-3 listHeader m-0 p-0 flex items-center">
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Specialist" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Branch" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Contact Info" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Joined On" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Status" />
                </p>
              </Col>
              <Col lg={2} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Actions" />
                </p>
              </Col>
            </Row>

            <Row className="bc-FFFFFF m-0 p-0">
              {[1, 2, 3, 4, 5].map((key, index) => (
                <Row className="m-0 p-0 flex items-center listAppointment">
                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {'Christine Brooks'}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'00001'}
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {'Branch Name'}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'203A Spanish Tn Rd, Kingston'}
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <img
                          src={mailIcon}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'random123@mail'}
                        </p>
                      </Stack>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center mt-2"
                      >
                        <img
                          src={callIcon}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'9874456321'}
                        </p>
                      </Stack>
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat">
                      {'23 Nov 2019'}
                    </p>
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <SwitchInput
                      label={true}
                      checked={checked}
                      onChange={(e) => handleSwitchChange(e)}
                      color="secondary"
                    />
                  </Col>

                  <Col lg={2} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="items-center "
                    >
                      <img
                        src={trash}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                      />
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                          <FormattedMessage id="View Details" />
                        </p>
                        <img
                          src={rightArrow}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                      </Stack>
                    </Stack>
                  </Col>
                </Row>
              ))}
            </Row>
          </>
        )}

        {tab === 4 && (
          <>
            <Row className="mt-3 listHeader m-0 p-0 flex items-center">
              <Col lg={3} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Branch" />
                </p>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Branch Manager" />
                </p>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Contact Info" />
                </p>
              </Col>
              <Col lg={3} md={6} sm={12}>
                <p className="p-0 m-0 fs-14 fw-400 fc-292D32 ff-Akkurat_Bold">
                  <FormattedMessage id="Actions" />
                </p>
              </Col>
            </Row>

            <Row className="bc-FFFFFF m-0 p-0">
              {[1, 2, 3, 4, 5].map((key, index) => (
                <Row className="m-0 p-0 flex items-center listAppointment">
                  <Col lg={3} md={6} sm={12}>
                    <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold">
                      {'Branch Name'}
                    </p>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat">
                      {'203A Spanish Tn Rd, Kingston'}
                    </p>
                  </Col>

                  <Col lg={3} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                      {'John Doe'}
                    </p>
                  </Col>

                  <Col lg={3} md={6} sm={12}>
                    <p className="p-0 m-0 fs-12 fw-400 fc-292D32 ff-Akkurat_Bold">
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <img
                          src={mailIcon}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'random123@mail'}
                        </p>
                      </Stack>
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center mt-2"
                      >
                        <img
                          src={callIcon}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                        <p className="p-0 m-0 fs-12 fc-292D32 fw-400 ff-Akkurat">
                          {'9874456321'}
                        </p>
                      </Stack>
                    </p>
                  </Col>

                  <Col lg={3} md={6} sm={12}>
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="items-center "
                    >
                      <img
                        src={trash}
                        alt="icon"
                        style={{ height: '24px', width: '24px' }}
                      />
                      <Stack
                        direction="horizontal"
                        gap={2}
                        className="items-center "
                      >
                        <p className="p-0 m-0 fs-14 fc-292D32 fw-400 ff-Akkurat_Bold underline underline-offset-4">
                          <FormattedMessage id="View Details" />
                        </p>
                        <img
                          src={rightArrow}
                          alt="icon"
                          style={{ height: '20px', width: '20px' }}
                        />
                      </Stack>
                    </Stack>
                  </Col>
                </Row>
              ))}
            </Row>
          </>
        )}

        {tab === 5 && (
          <>
            <Row className="bc-FFFFFF m-0 p-16 mt-3">
              <Col xs={12} md={12}>
                <h5 className="p-0 m-0 fs-18 fw-400 fc-292D32 ff-Akkurat_Bold">
                  {'Reviews'}
                </h5>
              </Col>

              <Col xs={12} md={3} className="mt-3">
                <p className="p-0 m-0 fs-32 fw-400 fc-292D32 ff-Akkurat_Bold">
                  {'4.6'}
                </p>
                <Stack
                  direction="horizontal"
                  spacing={1}
                  className="mt-1 items-center"
                >
                  {[1, 2, 3, 4, 5].map((star) => (
                    <div
                      key={star}
                      className={star <= 4 ? 'starfill' : 'starUnfill'}
                    >
                      {star <= 4 ? (
                        <StarIcon fontSize="medium" />
                      ) : (
                        <StarBorderIcon fontSize="medium" />
                      )}
                    </div>
                  ))}
                </Stack>
              </Col>

              <Col xs={12} md={7} className="">
                {[1, 2, 3].map((value, index) => (
                  <Col
                    xs={12}
                    md={12}
                    className="mt-3 border-b bColor-E3E3E3 pb-2"
                  >
                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="items-center"
                    >
                      <img
                        src={PhotoImage}
                        alt={'icon'}
                        style={{
                          height: '88px',
                          width: '88px',
                          borderRadius: '50%',
                        }}
                      />
                      <div>
                        <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                          John Doe
                        </p>
                        <p className="p-0 m-0 mt-2 fs-14 fc-A2A2A2 fw-400 ff-Akkurat">
                          22nd March, 2024
                        </p>
                      </div>
                    </Stack>

                    <Stack
                      direction="horizontal"
                      gap={3}
                      className="mt-3 items-center"
                    >
                      <>
                        <div className="flex">
                          {[1, 2, 3, 4, 5].map((star) => (
                            <div
                              key={star}
                              className={star <= 4 ? 'starfill' : 'starUnfill'}
                            >
                              {star <= 4 ? (
                                <StarIcon fontSize="medium" />
                              ) : (
                                <StarBorderIcon fontSize="medium" />
                              )}
                            </div>
                          ))}
                        </div>
                        <p className="p-0 m-0 fs-18 fc-292D32 fw-400 ff-Akkurat_Bold">
                          {'4'}
                        </p>
                      </>
                    </Stack>

                    <div className="mt-2">
                      <p className="p-0 m-0 fs-16 fc-A2A2A2 fw-400 ff-Akkurat">
                        {
                          'I was initially apprehensive, having no prior design experience. But the instructor, John Doe, did an amazing job of breaking down complex concepts into easily digestible modules. The video lectures were engaging, and the real-world examples really helped solidify my understanding.'
                        }
                      </p>
                    </div>
                  </Col>
                ))}
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default CompanyDetails;
