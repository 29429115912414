import React, { useContext, useEffect, useState, useRef } from 'react';
import { Row, Col, Stack } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'assets/styles/common.scss';
import { LocaleContext } from 'routes/Router';
import { FormattedMessage, useIntl } from 'react-intl';
import MuiBreadcrumbs from 'components/common/MuiBreadcrumbs';
import { superAdminProvider } from 'routes/AppUrls';
import ReusableButton from 'components/common/Button';
import leftArrow from 'assets/images/Dashboard/arrow-left.png';
import Specialist from 'assets/images/Dashboard/Specialist.png';
import SpecialistProvider from './specialist';
import SalonProvider from './salon';
import CompanyProvider from './company';

const OnHoldProvider = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { locale } = useContext(LocaleContext);

  const [breadcrumbsList, setBreadcrumbsList] = useState([
    {
      title: <FormattedMessage id="Providers" />,
      Link: superAdminProvider(locale),
      isDisbale: false,
    },
    {
      title: <FormattedMessage id="Request Details" />,
      Link: '',
      isDisbale: true,
    },
  ]);

  return (
    <Row className="bc-F2F3F6 m-0 p-0">
      <Col
        className={
          locale === 'en'
            ? 'commonEnContainer lg:ml-left'
            : 'commonArContainer lg:mr-right'
        }
      >
        <MuiBreadcrumbs size="14" list={breadcrumbsList} />
        <Row className="bc-FFFFFF m-0 p-16 mt-3">
          <div className="flex justify-between items-center">
            <h2 className="fc-292D32 fs-32 fw-400 ff-Akkurat_Bold m-0">
              <Stack direction="horizontal" gap={2} className="items-center">
                <img
                  src={leftArrow}
                  alt="icon"
                  style={{ height: '32px', width: '32px' }}
                  className="cursor-pointer"
                  onClick={() => navigate(superAdminProvider(locale))}
                />
                <p className="p-0 m-0">
                  <FormattedMessage id="Request Details" />
                </p>
              </Stack>
            </h2>
            <div className="flex">
              <Stack direction="horizontal" gap={3} className="items-center">
                <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#292D32"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 25px"
                  height="44px"
                  bgColor="transparent"
                  fontFamily="Akkurat"
                >
                  <FormattedMessage id="Delete Request" />
                </ReusableButton>

                <ReusableButton
                  fullWidth={false}
                  variant="contained"
                  color="#F5F5F5"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 25px"
                  height="44px"
                  bgColor="#292D32"
                  fontFamily="Akkurat"
                >
                  <FormattedMessage id="Promote" />
                </ReusableButton>
              </Stack>
            </div>
          </div>

          <Row className="m-0 p-0 mt-4 pt-2">
            <Col xs={12} md={4} className="items-center">
              <p className="m-0 p-0 fc-A4AAB2 fs-14 fw-400 ff-Akkurat">
                {'Requested by'}
              </p>
              <p className="m-0 p-0 fc-292D32 fs-18 fw-400 ff-Akkurat_Bold">
                {'Christine Brooks'}
              </p>
              <p className="m-0 p-0 fc-A4AAB2 fs-14 fw-400 ff-Akkurat">
                {'ID:'}
                <span className="m-0 p-0 fc-292D32 fs-14 fw-400 ff-Akkurat">
                  {'00001'}
                </span>
              </p>
            </Col>
            <Col xs={12} md={4} className="items-center text-center">
              <p className="m-0 p-0 fc-A4AAB2 fs-14 fw-400 ff-Akkurat">
                {'Business Type:'}
              </p>
              <Stack
                direction="horizontal"
                gap={2}
                className="items-center justify-center"
              >
                <img
                  src={Specialist}
                  alt="icon"
                  style={{ height: '32px', width: '32px' }}
                />
                <p
                  className="m-0 p-0 fc-292D32 fs-16 fw-400 ff-Akkurat_Bold"
                  className="p-0 m-0"
                >
                  <FormattedMessage id="Specialist" />
                </p>
              </Stack>
            </Col>
            <Col xs={12} md={4} className="items-center text-center">
              <p className="m-0 p-0 fc-A4AAB2 fs-14 fw-400 ff-Akkurat">
                {'Request Date'}
              </p>
              <p className="m-0 p-0 fc-292D32 fs-16 fw-400 ff-Akkurat">
                {'23 Nov 2019'}
              </p>
            </Col>
          </Row>

          <div className="mt-4">
            <p className="p-0 m-0 fc-292D32 fs-18 fw-400 ff-Akkurat_Bold">
              {'Business Details'}
            </p>
          </div>

          {
            <SpecialistProvider />
            // <SalonProvider />
            // <CompanyProvider />
          }

          <Row className="mt-4 items-end m-0 p-0">
            <div className="flex justify-end">
              <Stack direction="horizontal" gap={3} className="items-center">
                <ReusableButton
                  fullWidth={false}
                  variant="outlined"
                  color="#292D32"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 25px"
                  height="44px"
                  bgColor="transparent"
                  fontFamily="Akkurat"
                  onClick={() => navigate(superAdminProvider(locale))}
                >
                  <FormattedMessage id="Discard Changes" />
                </ReusableButton>

                <ReusableButton
                  fullWidth={false}
                  variant="contained"
                  color="#F5F5F5"
                  fontSize="16px"
                  fontWeight="400"
                  padding="12px 25px"
                  height="44px"
                  bgColor="#292D32"
                  fontFamily="Akkurat"
                >
                  <FormattedMessage id="Save Changes" />
                </ReusableButton>
              </Stack>
            </div>
          </Row>
        </Row>
      </Col>
    </Row>
  );
};

export default OnHoldProvider;
