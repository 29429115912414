import React, {
  createContext,
  useEffect,
  useState,
  lazy,
  Suspense,
} from 'react';
import {
  Routes,
  Navigate,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  availableLocales,
  isAuthenticated,
  getDefaultLanguage,
  getUserRole,
} from 'utils/general';
import { superAdminDashboard, getLandingURL, login } from './AppUrls';
import { allRoutes } from 'routes/RoutesConfig';
import Sidebar from 'components/sidebar';
import CustomNavbar from 'components/navbar';
import LangSwitcher from 'LangSwitcher';

// Dynamically import components

export const RenderRouteElement = ({
  children,
  isNoSidebar,
  isNoNavbar,
  isSiderBarIcon,
  isPrivate,
  isCommon,
  allowedRoles,
  locale,
}) => {
  const userRole = getUserRole();

  if (isAuthenticated() && allowedRoles && !allowedRoles.includes(userRole)) {
    return <Navigate replace to={getLandingURL(locale)} />;
  }

  const renderContentBasedOnRole = () => {
    switch (userRole) {
      case 'Admin':
        return <Navigate replace to={superAdminDashboard(locale)} />;
      default:
        return <Navigate replace to={getLandingURL(locale)} />;
    }
  };

  const getLangSwitcherAuthenticated = () => {
    const pathsToReturnEmpty = [getLandingURL(locale), login(locale)];

    return pathsToReturnEmpty.includes(window?.location?.pathname) ? (
      <LangSwitcher />
    ) : (
      ''
    );
  };

  return (
    <React.Fragment>
      {!isAuthenticated() && (!isPrivate || isCommon) ? (
        <>
          {getLangSwitcherAuthenticated()}
          {!isNoSidebar && <Sidebar />}
          {!isNoNavbar && <CustomNavbar />}
          {children}
        </>
      ) : isAuthenticated() && !isPrivate ? (
        renderContentBasedOnRole()
      ) : isAuthenticated() && isPrivate ? (
        <>
          {!isNoSidebar && <Sidebar />}
          {!isNoNavbar && <CustomNavbar />}
          {children}
        </>
      ) : (
        <Navigate replace to={getLandingURL(locale)} />
      )}
    </React.Fragment>
  );
};

export const LocaleContext = createContext({
  locale: '',
  setLocale: (newLocale) => {},
});

const Router = ({ LanguageSwitcher }) => {
  const navigate = useNavigate();
  const { pathname, search, hash } = useLocation();
  const defaultLocale = getDefaultLanguage('en');
  const pathnameLocale = pathname.substring(1, 3).toLowerCase();

  const [locale, setLocale] = useState(defaultLocale);

  useEffect(() => {
    if (availableLocales.includes(pathnameLocale)) {
      updateLocale(pathnameLocale);
    } else if (pathname === '/') {
      updateLocale(defaultLocale);
    } else if (!availableLocales.includes(pathnameLocale)) {
      updateLocale(pathnameLocale);
    }
  }, [pathname]);

  useEffect(() => {
    let lang = defaultLocale;

    if (availableLocales.includes(pathnameLocale)) {
      lang = pathnameLocale;
      setLanguageHandler(lang);
    } else if (pathname === '/') {
      setLanguageHandler(lang);
    }
  }, [locale]);

  const setLanguageHandler = (lang) => {
    document.documentElement.setAttribute('lang', lang);
    if (lang === 'en') {
      LanguageSwitcher('en');
    } else {
      LanguageSwitcher('ar');
    }
  };

  const updateLocale = (newLocale) => {
    const newPath = `/${newLocale}` + pathname.substring(3);

    if (locale !== newLocale) {
      if (
        newPath === `/${newLocale}/` ||
        newPath === `/${newLocale}` ||
        pathname === '/'
      ) {
        navigate(getLandingURL(newLocale));
      } else if (!availableLocales.includes(pathnameLocale)) {
        navigate(`${defaultLocale}${newPath}${hash}${search}`);
      } else {
        navigate(`${newPath}${hash}${search}`);
      }
    } else if (
      newPath === `/${newLocale}/` ||
      newPath === `/${newLocale}` ||
      pathname === '/'
    ) {
      if (isAuthenticated()) {
        navigate(superAdminDashboard(newLocale));
      } else {
        navigate(getLandingURL(newLocale));
      }
    }

    setLocale(newLocale);
  };

  const renderRouteWithChildren = (routes) => {
    return routes.map((route, index) => (
      <Route
        key={index}
        path={route.path(locale)}
        element={
          <RenderRouteElement
            isNoSidebar={route.isNoSidebar}
            isNoNavbar={route.isNoNavbar}
            isSiderBarIcon={route.isSiderBarIcon}
            isPrivate={route.isPrivate}
            isCommon={route.isCommon}
            allowedRoles={route.allowedRoles}
            locale={locale}
          >
            <Suspense fallback={<div>Loading...</div>}>
              {route.element}
            </Suspense>
          </RenderRouteElement>
        }
      >
        {route.children && renderRouteWithChildren(route.children)}
      </Route>
    ));
  };

  return (
    <LocaleContext.Provider value={{ locale, setLocale: updateLocale }}>
      <Routes baseName={`/:${locale}`}>
        {renderRouteWithChildren(allRoutes)}
        {/* {pathnameLocale && <Route path={"*"} element={<Error404 />} />} */}
      </Routes>
    </LocaleContext.Provider>
  );
};

export default Router;
