import React from 'react';
import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'assets/styles/common.scss';

const PhoneNumberInput = ({
  label,
  country,
  value,
  name,
  onCountryCodeChange,
  onPhoneNumberChange,
  phoneNumber,
  phoneNumberRef,
  placeholder,
  isPhoneNumberValid,
  isError,
  errorMessage,
  onlyCountries,
  suffixText, // New prop for the text at the end
}) => {
  return (
    <div className="phone-number-container">
      {label && <label className="phone-label">{label}</label>}
      <div className="phone-input-wrapper">
        <div className="country-code-input">
          <PhoneInput
            country={country}
            value={value}
            name={name}
            onChange={onCountryCodeChange}
            inputProps={{
              name: 'countryCode',
              required: true,
            }}
            placeholder="country"
            inputClass="custom-country-input"
            disableCountryCode={true}
            disableDropdown={false}
            onlyCountries={onlyCountries}
          />
        </div>

        <div className="phone-number-input">
          <div className="input-with-suffix">
            <input
              type="number"
              name={name}
              value={phoneNumber}
              onChange={onPhoneNumberChange}
              ref={phoneNumberRef}
              placeholder={placeholder}
              className={`custom-phone-input ${!isPhoneNumberValid ? 'invalid' : ''}`}
            />
            {suffixText && <span className="input-suffix">{suffixText}</span>}
          </div>
        </div>
      </div>

      {isError &&
      isError.phoneNumber?.error &&
      isError.phoneNumber?.errorMessage ? (
        <p className="error-message">{isError.phoneNumber.errorMessage}</p>
      ) : (
        !isPhoneNumberValid && (
          <p className="error-message">
            {errorMessage || 'Invalid phone number for selected country code'}
          </p>
        )
      )}
    </div>
  );
};

PhoneNumberInput.propTypes = {
  label: PropTypes.node,
  country: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onCountryCodeChange: PropTypes.func.isRequired,
  onPhoneNumberChange: PropTypes.func.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  phoneNumberRef: PropTypes.object,
  placeholder: PropTypes.string,
  isPhoneNumberValid: PropTypes.bool.isRequired,
  isError: PropTypes.object,
  errorMessage: PropTypes.string,
  onlyCountries: PropTypes.arrayOf(PropTypes.string),
  suffixText: PropTypes.string, // New prop type
};

PhoneNumberInput.defaultProps = {
  label: null,
  phoneNumberRef: null,
  placeholder: 'e.g: 8521364783',
  isError: null,
  errorMessage: null,
  onlyCountries: ['qa', 'us', 'in', 'gb'],
  suffixText: null, // Default value for new prop
};

export default PhoneNumberInput;
