import React, { useState } from 'react';
import { styled } from '@mui/system';
import {
  Tabs,
  TabsList as BaseTabsList,
  TabPanel as BaseTabPanel,
  Tab as BaseTab,
  tabClasses,
} from '@mui/base';
import { buttonClasses } from '@mui/base/Button';

const Tab = styled(BaseTab)`
  font-family: 'Akkurat';
  color: #a2a2a2;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  background-color: transparent;
  width: 100%;
  line-height: 1.5;
  padding: 6px;
  margin: 6px;
  border: none;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${(props) => (props.hoverDisable ? 'none' : '#292d32')};
    color: ${(props) => (props.hoverDisable ? 'none' : '#ffffff')};
    font-family: 'Akkurat';
    font-size: 16px !important;
    font-weight: 400;
  }

  &.${tabClasses.selected} {
    background-color: #292d32;
    color: #ffffff;
    align-items: center;
    font-family: 'Akkurat';
    font-size: 16px;
    font-weight: 400;
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(BaseTabPanel)`
  width: 100%;
  font-family: Inter_Regular;
  font-size: 0.875rem;
`;

const TabsList = styled(BaseTabsList)(
  ({ hasBorder }) => `
    min-width: 100%;
    background-color: #FFFFFF;
    border: ${hasBorder ? `1px solid ${hasBorder}` : 'none'};
    border-radius: 0px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    box-shadow: 0px 3px 16px 0px #00000014;

    @media (max-width: 614px) {
      flex-direction: column; /* Stack tabs vertically on small screens */
      padding: 10px;
    }

    @media (min-width: 614px) {
      flex-direction: row; /* Horizontal on large screens */
    }
  `
);

const ReusableTabs = ({
  tabs,
  defaultTab = 1,
  currentTab,
  image = false,
  hoverDisable,
  hasBorder,
}) => {
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const handleChange = (val) => {
    setSelectedTab(val);
    currentTab(val);
  };

  return (
    <Tabs
      value={selectedTab}
      onChange={(e, newValue) => handleChange(newValue)}
      className="p-0"
    >
      <TabsList hasBorder={hasBorder}>
        {tabs.map((tab, index) => (
          <Tab key={index} value={index + 1} hoverDisable={hoverDisable}>
            {image && (
              <img
                src={selectedTab === index + 1 ? tab.colorIcon : tab.icon}
                alt="icon"
                className="me-2"
              />
            )}
            {tab.label}
          </Tab>
        ))}
      </TabsList>
    </Tabs>
  );
};

export default ReusableTabs;
